/* eslint-disable no-var */
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from 'utils';

import { authStorage } from 'modules/auth/authStorage';
import {
  clearBudgetData,
  clearGlobalFilterDimensions,
  clearSelectedColumns,
  getAnalyticsDataStart,
  getBudgetDataStart,
  getChartDataStart,
  getViewsStart,
  getWidgetDataFailure,
  getWidgetDataStart,
  invalidateApiData,
  resetAdvancedConfiguration,
  setCurrentViewId,
} from 'modules/performance/performanceSlice';

import { localStorageService } from 'services/localStorage';
import { ADVERTISER_ID } from 'services/localStorage/constants';

import { orgApi } from './organizationApi';
import {
  Advertiser,
  AdvertisersPagingRequestValues,
  AdvertisersPagingResponse,
  Agency,
  Campaign,
  CreateAdvertiserRequest,
  CreateAgencyRequest,
  UpdateAdvertiserRequest,
  UpdateAgencyRequest,
} from './organizationApiTypes';
import {
  getAgenciesStart,
  getAgenciesFailure,
  getAgenciesSuccess,
  getAgenciesByRoleStart,
  getAgenciesByRoleFailure,
  getAgenciesByRoleSuccess,
  getCampaignsStart,
  updateAdvertiserStart,
  createAdvertiserStart,
  createAdvertiserSuccess,
  createAdvertiserFailure,
  updateAdvertiserSuccess,
  updateAdvertiserFailure,
  getCampaignsFailure,
  getCampaignsSuccess,
  updateAgencySuccess,
  updateAgencyFailure,
  createAgencySuccess,
  createAgencyFailure,
  createAgencyStart,
  updateAgencyStart,
  getCurrentAgencyStart,
  getCurrentAgencySuccess,
  getCurrentAgencyFailure,
  getCurrentAdvertiserSuccess,
  getCurrentAdvertiserFailure,
  getCurrentAdvertiserStart,
  setCurrentAdvertiser,
  getCurrentAdvertiserId,
  getAdvertisersSuccess,
  getAdvertisersFailure,
  getAdvertisersStart,
} from './organizationSlice';

function* getAgenciesFlow() {
  try {
    const response: Agency[] = yield call(orgApi.getAgencies);
    const authToken: string = yield call(authStorage.getIdToken);
    const selectedAdvertiserId: string = yield select(getCurrentAdvertiserId);
    const agencies = updateImageUrls(response, authToken);

    if (!selectedAdvertiserId && agencies) {
      const savedAdvertiserId = localStorageService.getItem<string>(ADVERTISER_ID) || '';
      const isAdvertiserInAgencies = agencies.some((agency) =>
        agency.advertisers.some((advertiser) => advertiser.id === savedAdvertiserId),
      );

      const defaultAdvertiserId = isAdvertiserInAgencies
        ? savedAdvertiserId
        : agencies.find((a) => a.advertisers.length)?.advertisers[0]?.id || '';

      yield put(setCurrentAdvertiser(defaultAdvertiserId));
    }

    yield put(getAgenciesSuccess(agencies));
  } catch (error) {
    yield put(getAgenciesFailure(getErrorMessage(error)));
  }
}

function* getAgenciesByRoleFlow() {
  try {
    const response: Agency[] = yield call(orgApi.getAgenciesByRole);

    yield put(getAgenciesByRoleSuccess(response));
  } catch (error) {
    yield put(getAgenciesByRoleFailure(getErrorMessage(error)));
  }
}

function* getCurrentAgencyFlow(action: PayloadAction<string>) {
  try {
    const response: Agency = yield call(orgApi.getAgency, action.payload);
    const authToken: string = yield call(authStorage.getIdToken);

    const agency = updateImageUrls([response], authToken);

    yield put(getCurrentAgencySuccess(agency[0]));
  } catch (error) {
    yield put(getCurrentAgencyFailure(getErrorMessage(error)));
  }
}

function* getCurrentAdvertiserFlow(action: PayloadAction<string>) {
  try {
    const response: Advertiser = yield call(orgApi.getAdvertiser, action.payload);

    yield put(getCurrentAdvertiserSuccess(response));
  } catch (error) {
    yield put(getCurrentAdvertiserFailure(getErrorMessage(error)));
  }
}

function* createAdvertiserFlow(action: PayloadAction<CreateAdvertiserRequest>) {
  try {
    yield call(orgApi.createAdvertiser, action.payload);
    const authToken: string = yield call(authStorage.getIdToken);
    const response: Agency[] = yield call(orgApi.getAgencies);
    const agencies = updateImageUrls(response, authToken);

    yield put(createAdvertiserSuccess(agencies));
  } catch (error) {
    yield put(createAdvertiserFailure(getErrorMessage(error)));
  }
}

function* updateAdvertiserFlow(action: PayloadAction<UpdateAdvertiserRequest>) {
  try {
    yield call(orgApi.updateAdvertiser, action.payload);
    const authToken: string = yield call(authStorage.getIdToken);
    const response: Agency[] = yield call(orgApi.getAgencies);
    const agencies = updateImageUrls(response, authToken);

    yield put(updateAdvertiserSuccess(agencies));
  } catch (error) {
    yield put(updateAdvertiserFailure(getErrorMessage(error)));
  }
}

function* getCampaignsFlow(action: PayloadAction<string>) {
  try {
    const response: Campaign[] = yield call(orgApi.getCampaigns, action.payload);

    yield put(getCampaignsSuccess(response));
  } catch (error) {
    yield put(getCampaignsFailure(getErrorMessage(error)));
  }
}

function* updateAgencyFlow(action: PayloadAction<UpdateAgencyRequest>) {
  try {
    yield call(orgApi.updateAgency, action.payload);
    const authToken: string = yield call(authStorage.getIdToken);
    const response: Agency[] = yield call(orgApi.getAgencies);
    const agencies = updateImageUrls(response, authToken);

    yield put(updateAgencySuccess(agencies));
  } catch (error) {
    yield put(updateAgencyFailure(getErrorMessage(error)));
  }
}

function* createAgencyFlow(action: PayloadAction<CreateAgencyRequest>) {
  try {
    const agency: Agency = yield call(orgApi.createAgency, action.payload);

    yield put(createAgencySuccess(agency));
  } catch (error) {
    yield put(createAgencyFailure(getErrorMessage(error)));
  }
}

function updateImageUrls(agencies: Agency[], authToken: string): Agency[] {
  return agencies.map((agency) => ({
    ...agency,
    logoUrl: agency.logoUrl ? `${agency.logoUrl}&idToken=${authToken}` : agency.logoUrl,
    wordMarkUrl: agency.wordMarkUrl
      ? `${agency.wordMarkUrl}&idToken=${authToken}`
      : agency.wordMarkUrl,
  }));
}

function* changeAdvertiserFlow(action: PayloadAction<string>) {
  try {
    localStorageService.setItem(ADVERTISER_ID, action.payload);

    yield put(clearBudgetData());
    yield put(clearSelectedColumns());
    yield put(resetAdvancedConfiguration());
    yield put(clearGlobalFilterDimensions());
    yield put(invalidateApiData());

    yield put(getViewsStart(action.payload));
    yield put(setCurrentViewId(''));
    yield put(getWidgetDataStart());
    yield put(getChartDataStart());
    yield put(getAnalyticsDataStart());
    yield put(getBudgetDataStart());
  } catch (error) {
    yield put(getWidgetDataFailure(getErrorMessage(error)));
  }
}

function* getAdvertisersFlow(action: PayloadAction<AdvertisersPagingRequestValues>) {
  try {
    const response: AdvertisersPagingResponse = yield call(orgApi.getAdvertisers, action.payload);

    yield put(getAdvertisersSuccess(response));
  } catch (error) {
    yield put(getAdvertisersFailure(getErrorMessage(error)));
  }
}

export function* orgSagas() {
  yield all([
    takeLatest(getAgenciesStart.type, getAgenciesFlow),
    takeLatest(getAgenciesByRoleStart.type, getAgenciesByRoleFlow),
    takeLatest(getCurrentAgencyStart.type, getCurrentAgencyFlow),
    takeLatest(getCurrentAdvertiserStart.type, getCurrentAdvertiserFlow),
    takeLatest(createAdvertiserStart.type, createAdvertiserFlow),
    takeLatest(updateAdvertiserStart.type, updateAdvertiserFlow),
    takeLatest(getCampaignsStart.type, getCampaignsFlow),
    takeLatest(createAgencyStart.type, createAgencyFlow),
    takeLatest(updateAgencyStart.type, updateAgencyFlow),
    takeLatest(setCurrentAdvertiser.type, changeAdvertiserFlow),
    takeLatest(getAdvertisersStart.type, getAdvertisersFlow),
  ]);
}
