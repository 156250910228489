/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from 'utils';

import { PagingRequestValues } from 'modules/organization/organizationApiTypes';

import { reportApi } from './reportApi';
import {
  ReportCreateRequest,
  ReportsPagingResponse,
  ReportTemplate,
  PerformanceReport,
  DeleteReportRequest,
  DownloadReportRequest,
  ReportStatusResponse,
  ReportStatusRequest,
  AllTouchReportCreateRequest,
  AllTouchReport,
} from './reportApiTypes';
import {
  deleteReportFailure,
  deleteReportStart,
  deleteReportSuccess,
  getReportsFailure,
  getReportsStart,
  getReportsSuccess,
  getReportTemplatesFailure,
  getReportTemplatesStart,
  getReportTemplatesSuccess,
  saveReportFailure,
  savePerformanceReportStart,
  saveReportSuccess,
  downloadReportStart,
  downloadReportSuccess,
  downloadReportFailure,
  getReportStatusSuccess,
  getReportStatusFailure,
  getReportStatusStart,
  saveAllTouchReportStart,
  saveAllTouchReportSuccess,
} from './reportSlice';
import { getReportFileFormat } from './utils';

function* getReportsFlow(action: PayloadAction<PagingRequestValues>) {
  try {
    const response: ReportsPagingResponse = yield call(reportApi.getReports, action.payload);

    yield put(getReportsSuccess(response));
  } catch (error) {
    yield put(getReportsFailure(getErrorMessage(error)));
  }
}

function* getReportTemplatesFlow() {
  try {
    const response: ReportTemplate[] = yield call(reportApi.getReportTemplates);

    yield put(getReportTemplatesSuccess(response));
  } catch (error) {
    yield put(getReportTemplatesFailure(getErrorMessage(error)));
  }
}

function* savePerformanceReportFlow(action: PayloadAction<ReportCreateRequest>) {
  try {
    const response: PerformanceReport = yield call(
      reportApi.savePerformanceReport,
      action.payload.report,
    );

    yield put(saveReportSuccess(response));
    action.payload.resolve(response);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(saveReportFailure(errorMessage));
    action.payload.reject(errorMessage);
  }
}

function* saveAllTouchReportFlow(action: PayloadAction<AllTouchReportCreateRequest>) {
  try {
    const response: AllTouchReport = yield call(
      reportApi.saveAllTouchReport,
      action.payload.report,
    );

    yield put(saveAllTouchReportSuccess(response));
    action.payload.resolve(response);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(saveReportFailure(errorMessage));
    action.payload.reject(errorMessage);
  }
}

function* deleteReportFlow(action: PayloadAction<DeleteReportRequest>) {
  try {
    yield call(reportApi.deleteReport, action.payload.reportId);

    yield put(deleteReportSuccess());
    action.payload.resolve();
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(deleteReportFailure(errorMessage));
    action.payload.reject(errorMessage);
  }
}

function* getReportDownloadFlow(action: PayloadAction<DownloadReportRequest>) {
  try {
    const response: Blob = yield call(reportApi.downloadReport, action.payload.reportId);

    fileDownload(response, `${action.payload.reportName}${getReportFileFormat(response)}`);
    action.payload.resolve();
    yield put(downloadReportSuccess());
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(downloadReportFailure(errorMessage));
    action.payload.reject(errorMessage);
  }
}

function* getReportsStatusFlow(action: PayloadAction<ReportStatusRequest>) {
  try {
    const response: ReportStatusResponse = yield call(
      reportApi.getReportStatus,
      action.payload.reportId,
    );

    action.payload.resolve(response);
    yield put(getReportStatusSuccess(response));
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(getReportStatusFailure(errorMessage));
    action.payload.reject(errorMessage);
  }
}

export function* reportSagas() {
  yield all([
    takeLatest(getReportTemplatesStart.type, getReportTemplatesFlow),
    takeLatest(getReportsStart.type, getReportsFlow),
    takeLatest(savePerformanceReportStart.type, savePerformanceReportFlow),
    takeLatest(saveAllTouchReportStart.type, saveAllTouchReportFlow),
    takeLatest(deleteReportStart.type, deleteReportFlow),
    takeLatest(downloadReportStart.type, getReportDownloadFlow),
    takeLatest(getReportStatusStart.type, getReportsStatusFlow),
  ]);
}
