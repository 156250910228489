import { AllTouchPreviewTableProps } from './ReportsTypes';
import { Spin, Table } from 'components/common';
import { TableProps, Tooltip } from 'antd';
import { usePerformance } from '../../modules/performance/usePerformance';
import { useWindowSize } from 'usehooks-ts';
import { getYScroll } from '../XandrSettings/utils';

export const AllTouchPreviewTable = ({
  page,
  setPage,
  pageSize,
  setPageSize,
}: AllTouchPreviewTableProps) => {
  const { height = 0 } = useWindowSize();
  const { allTouch, isAllTouchPreviewLoading } = usePerformance();

  const renderEllipsisWithTooltip = (text: string) => (
    <Tooltip title={text}>
    <span
      style={{
        display: 'inline-block',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
      }}
    >
      {text}
    </span>
    </Tooltip>
  );

  const columns: TableProps['columns'] = [
    {
      dataIndex: 'uniqueId',
      title: 'Unique ID',
      fixed: 'left',
      width: 192,
      ellipsis: true,
      render: renderEllipsisWithTooltip,
    },
    {
      dataIndex: 'pixelDateTime',
      title: 'Conversion Timestamp',
      width: 192,
      ellipsis: true,
      render: renderEllipsisWithTooltip,
    },
    {
      dataIndex: 'impDateTime',
      title: 'Impression Timestamp',
      width: 192,
      ellipsis: true,
      render: renderEllipsisWithTooltip
    },
    {
      dataIndex: 'subcategory',
      title: 'Subcategory',
      width: 128,
    },
    {
      dataIndex: 'creative',
      title: 'Creative',
      width: 256,
      ellipsis: true,
      render: renderEllipsisWithTooltip,
    },
    {
      dataIndex: 'publisher',
      title: 'Publisher',
      width: 128,
    },
    {
      dataIndex: 'audience',
      title: 'Audience',
      width: 192,
    },
    {
      dataIndex: 'mediaType',
      title: 'Media Type',
      width: 128,
    },
    {
      dataIndex: 'dma',
      title: 'DMA',
      width: 256,
    },
    {
      dataIndex: 'region',
      title: 'State',
      width: 64,
    },
    {
      dataIndex: 'deviceType',
      title: 'Device Type',
      width: 128,
    },
    {
      dataIndex: 'index',
      title: 'Times Exposed',
      width: 128,
    },
    {
      dataIndex: 'timeToConvert',
      title: 'Time to Convert (hrs)',
      width: 128,
    },
    {
      dataIndex: 'hashedIp',
      title: 'Hashed Ip',
      width: 128,
      ellipsis: true,
      render: renderEllipsisWithTooltip,
    }
  ];
  const totalWidth = columns.reduce((sum, col) => sum + (col.width as number || 100), 0);


  return (
    <Spin spinning={isAllTouchPreviewLoading}>
      <Table
        className="overflow-x-auto"
        dataSource={allTouch?.data.map(((data, index) => ({
          ...data,
          key: data.uniqueId ?? `row-${index}`,
          children: Array.isArray(data.impressions)
            ? data.impressions.map((imp, i) => ({
              ...imp,
              key: `child-${data.uniqueId}-${i}`,
            }))
            : [],
        })))}
        columns={columns}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: allTouch?.totalCount,
          onChange: (newPage, newPageSize) => {
            newPage != page ? setPage(newPage) : setPage(1);
            setPageSize(newPageSize);
          }
        }}
        tableLayout="fixed"
        style={{ width: '100%' }}
        scroll={{ x: totalWidth, ...getYScroll(pageSize, height) }}
        expandable={{
          expandedRowClassName: () => 'ctv-expanded-row',
        }}

      />
    </Spin>
  )
};