import { UserRequestValues } from 'modules/auth/types';

import { api } from 'services/api';

import { USERS_URL, INVITE_USER_URL, UPDATE_MEMBER_URL, MEMBERS_URL } from './constants';
import {
  MembersPagingRequestValue,
  MembersPagingResponse,
  UpdateMemberRequestValues,
  UserInviteRequestValues,
} from './userApiTypes';

const getUser = () => {
  return api.get(USERS_URL);
};

const getMembers = (payload: MembersPagingRequestValue) => {
  return api.get<MembersPagingResponse>(
    `${MEMBERS_URL}?page=${payload.page}&size=${payload.size}&agencyId=${payload.agencyId}&email=${payload.email}`,
  );
};

const inviteUser = (data: UserInviteRequestValues) => {
  return api.post(INVITE_USER_URL, data);
};

const updateUser = (data: UserRequestValues) => {
  return api.put(USERS_URL, data);
};

const updateMember = (data: Omit<UpdateMemberRequestValues, 'currentSelectedAgencyId'>) => {
  return api.put(UPDATE_MEMBER_URL, data);
};

const deleteUser = (subId: string) => {
  return api.delete(`${USERS_URL}/${subId}`);
};

export const userApi = {
  getUser,
  getMembers,
  updateMember,
  inviteUser,
  updateUser,
  deleteUser,
};
