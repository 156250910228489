import { api } from 'services/api';

import { ADVERTISERS_URL, AGENCIES_URL, CAMPAIGNS_URL, AGENCIES_BY_ROLE_URL } from './constants';
import {
  Advertiser,
  AdvertisersPagingRequestValues,
  AdvertisersPagingResponse,
  Agency,
  Campaign,
  CreateAdvertiserRequest,
  CreateAgencyRequest,
  UpdateAdvertiserRequest,
  UpdateAgencyRequest,
} from './organizationApiTypes';

const getAgencies = () => {
  return api.get<Agency[]>(AGENCIES_URL);
};

const getAgenciesByRole = () => {
  return api.get<Agency[]>(AGENCIES_BY_ROLE_URL);
};

const getAgency = (payload: string) => {
  return api.get<Agency>(`${AGENCIES_URL}/${payload}`);
};

const getAdvertiser = (payload: string) => {
  return api.get<Advertiser>(`${ADVERTISERS_URL}/${payload}`);
};

const createAdvertiser = (payload: CreateAdvertiserRequest) => {
  return api.post(ADVERTISERS_URL, payload);
};

const updateAdvertiser = (payload: UpdateAdvertiserRequest) => {
  return api.put(ADVERTISERS_URL, payload);
};

const createAgency = (payload: CreateAgencyRequest) => {
  return api.post(AGENCIES_URL, payload);
};

const updateAgency = (payload: UpdateAgencyRequest) => {
  return api.put(AGENCIES_URL, payload);
};

const getCampaigns = (payload: string) => {
  return api.get<Campaign[]>(`${CAMPAIGNS_URL}?advertiserId=${payload}`);
};

const getAdvertisers = (payload: AdvertisersPagingRequestValues) => {
  const sortBy = payload.sortBy ? `&sortBy=${payload.sortBy}` : '';
  const direction = payload.direction ? `&direction=${payload.direction}` : '';

  const optionalParamsString = sortBy && direction ? sortBy.concat(direction) : '';

  return api.get<AdvertisersPagingResponse>(
    `${ADVERTISERS_URL}?page=${payload.page}&size=${payload.size}&agencyId=${payload.agencyId}${optionalParamsString}`,
  );
};

export const orgApi = {
  getAgencies,
  getAgenciesByRole,
  getAgency,
  getAdvertiser,
  getAdvertisers,
  createAdvertiser,
  updateAdvertiser,
  getCampaigns,
  createAgency,
  updateAgency,
};
