export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgotPassword',
  CREATE_NEW_PASSWORD: '/createNewPassword',
  SIGNUP: '/signup',
  UIKIT: '/pages/UIKit',
  TEST: '/pages/test',
  PERFORMANCE_DASHBOARD: '/pages/performanceDashboard',
  USER_SETTINGS: '/pages/userSettings',
  PUBLISHERS_SETTINGS: '/pages/publishersSettings',
  MEMBERS_SETTINGS: '/pages/membersSettings',
  ADVERTISER_SETTINGS: '/pages/advertiserSettings',
  AGENCY_SETTINGS: '/pages/agencySettings',
  XANDR_SETTINGS: '/pages/xandrSettings',
  PIXEL_CODE: '/pages/pixelCode',
  PIXEL_VERIFICATION: '/pages/pixelVerification',
  REPORTS: '/pages/reports',
} as const;

export const EXTERNAL_LINKS = {
  PIXEL_INSTALLATION:
    'https://docs.google.com/document/d/1kJZYQewtoRYLr9A-WHsTAaHcIhDbWHovVhQlSfPGSCE/edit?tab=t.0',
} as const;

export type Keys = keyof typeof ROUTES;
export type Route = (typeof ROUTES)[Keys];
