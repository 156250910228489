import { PagingRequestValues } from 'modules/organization/organizationApiTypes';

import { api } from 'services/api';

import {
  REPORTS_TEMPLATES_URL,
  REPORTS_URL,
  REPORTS_DOWNLOAD_URL,
  REPORTS_STATUS_URL,
  ALL_TOUCH_REPORTS_URL,
  PERFORMANCE_REPORTS_URL,
} from './constants';
import {
  ReportsPagingResponse,
  ReportTemplate,
  PerformanceReport,
  PerformanceReportCreatePayload,
  ReportStatusResponse,
  AllTouchReportCreatePayload,
} from './reportApiTypes';

const getReports = (payload: PagingRequestValues) => {
  const sortBy = payload.sortBy ? `&sortBy=${payload.sortBy}` : '';
  const direction = payload.direction ? `&direction=${payload.direction}` : '';
  const search = payload.search ? `&search=${payload.search}` : '';

  const optionalParamsString = sortBy && direction ? sortBy.concat(direction, search) : search;

  return api.get<ReportsPagingResponse>(
    `${REPORTS_URL}?advertiserId=${payload.advertiserId}&page=${payload.page}&size=${payload.size}${optionalParamsString}`,
  );
};

const savePerformanceReport = (
  payload: PerformanceReportCreatePayload | AllTouchReportCreatePayload,
) => {
  return api.post<PerformanceReport>(PERFORMANCE_REPORTS_URL, payload);
};

const saveAllTouchReport = (payload: AllTouchReportCreatePayload) => {
  return api.post<PerformanceReport>(ALL_TOUCH_REPORTS_URL, payload);
};

const getReportTemplates = () => {
  return api.get<ReportTemplate[]>(REPORTS_TEMPLATES_URL);
};

const downloadReport = (reportId: string) => {
  return api.get<unknown>(`${REPORTS_DOWNLOAD_URL}/${reportId}`, { responseType: 'blob' });
};

const getReportStatus = (reportId: string) => {
  return api.get<ReportStatusResponse>(`${REPORTS_STATUS_URL}/${reportId}`);
};

const deleteReport = (reportId: string) => {
  return api.delete(`${REPORTS_URL}/${reportId}`);
};

export const reportApi = {
  getReports,
  savePerformanceReport,
  saveAllTouchReport,
  getReportTemplates,
  deleteReport,
  downloadReport,
  getReportStatus,
};
