/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux';

import { CreateUpdatePublisherRequest, Publisher } from './publisherApiTypes';
import {
  getPublishersStart,
  getPublisherState,
  createPublisherStart,
  updatePublisherStart,
  deletePublisherStart,
  uploadPublisherFileStart,
} from './publisherSlice';

export const usePublisher = () => {
  const dispatch = useDispatch();
  const state = useSelector(getPublisherState);

  function getPublishers() {
    dispatch(getPublishersStart({ page: 0, size: 10000 }));
  }

  function createPublisher(createUpdatePublisherRequest: CreateUpdatePublisherRequest) {
    dispatch(createPublisherStart(createUpdatePublisherRequest));
  }

  function updatePublisher(createUpdatePublisherRequest: CreateUpdatePublisherRequest) {
    dispatch(updatePublisherStart(createUpdatePublisherRequest));
  }

  function deletePublisher(publisher: Publisher) {
    dispatch(deletePublisherStart(publisher));
  }

  function uploadPublisherFile(file: any) {
    dispatch(uploadPublisherFileStart(file));
  }

  const publishers = state.publishers;
  const errorText = state.error;
  const isLoading = state.isLoading;
  const refreshFlag = state.refreshFlag;

  return {
    getPublishers,
    publishers,
    errorText,
    isLoading,
    refreshFlag,
    createPublisher,
    updatePublisher,
    deletePublisher,
    uploadPublisherFile,
  };
};
