export const VIEW_URL = 'view';

export const WIDGET_URL = 'performance/widget';

export const BUDGET_URL = 'performance/widget-budget';

export const NESTED_ANALYTICS_URL = 'performance/nested-analytics';

export const CHART_URL = 'performance/chart';

export const INCREMENTALITY_URL = 'performance/incrementality';

export const HALO_URL = 'performance/halo';

export const ANALYTICS_URL = 'performance/analytics';

export const ALL_TOUCH_URL = 'performance/all-touch';

export const CREATIVE_URL = 'creative';

export const CREATIVE_NAMES_URL = 'creative/names';

export const PUBLISHER_URL = 'publisher';

export const SPEND_BUDGET_METRIC = 'SPEND';

export const CAMPAIGN_DIMENSION = 'CAMPAIGN';

export const DAILY_CADENCE = 'DAILY';

export const REACH_METRIC = 'REACH';

export const FREQUENCY_METRIC = 'FREQUENCY';
