import { DateRangeValue } from 'components/common';

import { Metrics } from 'modules/misc/miscApiTypes';
import { Advertiser } from 'modules/organization/organizationApiTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type View = {
  advertiserId: string | null;
  viewId?: string | null;
  name: string;
  metrics: string[];
  graphs: string[];
  dimensions?: string[] | null;
  columns?: string[] | null;
  isDefault?: boolean | null;
  isAdvancedAnalyticsAllowed?: boolean | null;
  isIncrementalityAllowed?: boolean | null;
  isHaloEffectAllowed?: boolean | null;
  isCustom?: boolean | null;
};

export type PerformanceState = {
  isWidgetsLoading: boolean;
  isChartLoading: boolean;
  isViewLoading: boolean;
  isGridLoading: boolean;
  isFiltersLoading: boolean;
  isIncrementalityLoading: boolean;
  isAllTouchPreviewLoading: boolean;
  isHaloLoading: boolean;
  error: string | null;
  views: View[] | null;
  widgets: WidgetResponse | null;
  displayWidgets: WidgetOption[] | null;
  currentViewId: string;
  analytics: AnalyticsResponse | null;
  analyticsPage: number;
  analyticsPageSize: number;
  analyticsOrder: string | null;
  analyticsOrderBy: string | null;
  allTouch: AllTouchResponse | null;
  incrementalityOrder?: string | null;
  haloOrder?: string | null;
  charts: PerformanceResponse | null;
  creatives: Creative[] | null;
  creativeNames: string[] | null;
  publishers: Publisher[] | null;
  customView?: View | null;
  selectedMetrics?: string[] | null;
  incrementalities?: Incrementality[] | null;
  overallIncrementality?: Incrementality | null;
  halo?: Halo | null;
  startDate?: string | null;
  endDate?: string | null;
  advancedConfiguration: AdvancedConfiguration | null;
  defaultAdvancedConfiguration: AdvancedConfiguration | null;
  incrementalityFilters: IncrementalityFilters;
  haloFilter: string;
  dateRange?: DateRangeValue | null;
  budget?: BudgetResponse | null;
  range?: string | null;
  selectedColumns?: string[] | null;
  globalFilterDimensions?: DimensionFilter[] | [];
  cadence: string;
  cumulative?: boolean;
  selectedDimensions: [string | null, string | null];
  showSecondFilter: boolean;
  activeTab: string;
};

export type AdvancedConfiguration = {
  methodology: string; // 'FIRST_TOUCH', 'LAST_TOUCH'
  showUniques: boolean; // 'Total' or 'Unique'
  conversionWindow: number; // 1, 7, 14, 30
};

export type IncrementalityFilters = {
  dimension?: string | null;
  metric: string;
};

export type DeleteViewRequest = {
  advertiserId: string;
  viewId: string;
};

export type DeleteViewResponse = {
  isSelectedViewDeleted: boolean;
  views: View[];
};

export type CreateViewRequest = {
  name: string;
  advertiserId: string;
  isDefault: boolean;
  viewId?: string;
};

export type DimensionFilter = {
  dimension?: string;
  metric?: string;
  value?: string;
  include: string[];
  exclude: string[];
};

export type PerformanceResponse = {
  [key: string]: Record<string, number>;
};

export type WidgetResponse = {
  current: Record<string, number | null>;
  previous: Record<string, number | null>;
  percent: Record<string, number | null>;
  prevStartDateTime: string;
  prevEndDateTime: string;
  timeSlice: string;
};

export type WidgetOption = {
  displayName: string;
  selected: boolean;
  unit: string;
  current?: number | null;
  previous?: number | null;
  percent?: number | null;
  hasPopover: boolean;
  key: string;
  timeSlice?: string | null;
  timeSliceOption?: string | null;
};

export type AnalyticsResponse = {
  data: Record<string, unknown>[];
  totalPages: number;
  totalCount: number;
};

export type AllTouchResponse = {
  data: Record<string, unknown>[];
  totalPages: number;
  totalCount: number;
}

export type FilterWidgets = {
  viewMetrics: string[];
  graphs: string[];
  displayNames: Metrics;
  timeSlice: string;
  timeSliceOption: string;
  widgets: WidgetResponse;
};

export type WidgetRequest = {
  metrics: string[];
  advertiserId: string;
  startDateTime: string;
  endDateTime: string;
  timeSlice: string;
  dimensionFilters: DimensionFilter[];
  methodology?: string | null;
  showUniques?: boolean | null;
  conversionWindow?: number | null;
};

export type BudgetRequest = {
  advertiserId: string;
  dimensionFilters?: DimensionFilter[] | null;
};

export type IncrementalityRequest = {
  dimension?: string | null;
  metric: string;
  advertiserId: string;
  methodology?: string | null;
  showUniques?: boolean | null;
  conversionWindow?: number | null;
  startDateTime: string;
  endDateTime: string;
  dimensionFilters?: DimensionFilter[] | null;
  order?: string | null; // 'ASC', 'DESC'
};

export type HaloRequest = {
  metric: string;
  advertiserId: string;
  methodology?: string | null;
  showUniques?: boolean | null;
  conversionWindow?: number | null;
  startDateTime: string;
  endDateTime: string;
  dimensionFilters?: DimensionFilter[] | null;
  order?: string | null; // 'ASC', 'DESC'
};

export type BudgetResponse = {
  budget?: number | null;
  spend?: number | null;
  spendToBudget?: number | null;
  pacing?: number | null;
  startDate?: string | null;
  endDate?: string | null;
};

export type Incrementality = {
  name: string;
  exposed_conversions: number;
  exposed_conversions_rate: number;
  exposed_reach: number;
  control_reach: number;
  control_conversions: number;
  control_conversions_rate: number;
  incrementality_lift: number;
};

export type IncrementalityResponse = {
  incrementalities?: Incrementality[] | null;
  overallIncrementality?: Incrementality | null;
};

export type Halo = {
  totalExposedConversions: number;
  totalControlConversions: number;
  haloByChannels: ChannelHalo[];
};

export type ChannelHalo = {
  channel: string;
  exposedConversions: number;
  controlConversions: number;
};

export type NestedAnalyticsRequest = {
  parentDimension: string;
  childDimension: string;
  metrics: string[];
  advertiserId: string;
  startDateTime: string;
  endDateTime: string;
  page: number;
  size: number;
  order?: string; // ASC, DESC
  orderBy?: string; // metric
  dimensionFilters?: DimensionFilter[];
  showUniques?: boolean | null;
  methodology?: string | null;
  conversionWindow?: number | null;
};

export type ChartRequest = {
  metrics: string[];
  advertiserId: string;
  methodology?: string | null;
  showUniques?: boolean | null;
  cumulative?: boolean | null;
  conversionWindow?: number | null;
  startDateTime: string;
  endDateTime: string;
  cadence: string; // Daily
  dimensionFilters?: DimensionFilter[];
};

export type AnalyticsRequest = {
  dimension: string; // MEDIA TYPE
  metrics: string[];
  advertiserId: string;
  startDateTime: string;
  endDateTime: string;
  page: number;
  size: number;
  order?: string; // ASC, DESC
  orderBy?: string; // metric
  dimensionFilters?: DimensionFilter[];
  conversionWindow?: number | null;
  showUniques?: boolean | null;
  methodology?: string | null;
};

export type AllTouchRequest = {
  advertiserId: string;
  campaign: string;
  conversionWindow: number;
  startDateTime: string;
  endDateTime: string;
  eventType: string;
  subcategories: string[];
  page: number;
  size: number;
}

export type PublishersPagingResponse = {
  data: Publisher[];
  totalPages: number;
};

export type CreativesPagingResponse = {
  data: Creative[];
  totalPages: number;
};

export type CreativeNamesPagingResponse = {
  data: string[];
  totalPages: number;
};

export type Creative = {
  id: number;
  name: string;
  advertiser: Advertiser;
  publisher: Publisher;
  creationDate: string;
  modificationDate: string;
  new: boolean;
};

export type Publisher = {
  id: number;
  name: string;
  displayName: string;
  description: string;
  icon: string;
  creationDate: string;
  modificationDate: string;
};

export const MediaType = {} as const;

export const TabKeys = {
  REAL_IMPACT: {
    label: 'Real Impact',
    value: 'REAL_IMPACT',
  },
  INCREMENTALITY: {
    label: 'Incrementality',
    value: 'INCREMENTALITY',
  },
  RIPPLE_EFFECT: {
    label: 'Ripple Effect',
    value: 'RIPPLE_EFFECT',
  },
};

export const sortDirections = {
  ascend: 'ASC',
  descend: 'DESC',
};
