import { Flex, MenuProps } from 'antd';
import { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import { Filter as FilterIcon } from 'feather-icons-react';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { GlobalFilterTemplate } from 'components/GlobalFilter/GlobalFilterTemplate';
import { RadioOptions } from 'components/GlobalFilter/constants';
import { visibleSubcategories } from 'components/PerformanceTable/performanceTypes';
import { Chip, Dropdown, Tooltip } from 'components/common';
import { Select } from 'components/common/select';

import {
  useAudiencesQuery,
  useConstantsQuery,
  useCreativesQuery,
  useInsertionOrdersQuery,
  useLineItemsQuery,
  useMediaTypesQuery,
  usePublishersQuery,
  useSubcategoriesQuery,
  useSplitsQuery,
} from 'modules/apiData/dataApiSlice';
import { Dimension, Metric } from 'modules/misc/miscApiTypes';
import { useOrganization } from 'modules/organization/useOrganization';
import { DimensionFilter } from 'modules/performance/performanceApiTypes';

const MAX_LENGHT = 35;
const truncateWithTooltip = (text: string) =>
  text.length > MAX_LENGHT ? (
    <Tooltip
      className="text-base-sm"
      title={<span className="text-base-sm font-normal">{text}</span>}
    >
      {text}
    </Tooltip>
  ) : (
    <span className="text-base-sm">{text}</span>
  );

interface AllOptions {
  [key: string]: Partial<Dimension | Metric>;
}
type Option = { label: ReactNode; value: string };

const convertDimensionsToTags = (allOptions: AllOptions, dimensions: DimensionFilter[]) => {
  return dimensions.flatMap((dimension) => {
    const tags: {
      key: string;
      label: string;
      value: { dimension: DimensionFilter; radioOption: RadioOptions };
    }[] = [];

    const dimensionNameLabel = allOptions[dimension.dimension! || dimension.metric!].shownName;

    if (dimension?.include?.length > 0) {
      const label = `${dimensionNameLabel} is '${dimension?.include.join(', ')}'`;

      tags.push({
        key: label,
        label,
        value: { dimension, radioOption: RadioOptions.INCLUDE },
      });
    }

    if (dimension?.exclude?.length > 0) {
      const label = `${dimensionNameLabel} is not '${dimension?.exclude.join(', ')}'`;

      tags.push({
        key: label,
        label,
        value: { dimension, radioOption: RadioOptions.EXCLUDE },
      });
    }

    return tags;
  });
};

const createDeepCopy = (dimensions: DimensionFilter[]) =>
  dimensions.map((item) => ({
    ...item,
    ...(item.include && { include: [...item.include] }),
    ...(item.exclude && { exclude: [...item.exclude] }),
  }));

const createDimensionOption = (value: string) => ({
  label: truncateWithTooltip(value),
  value,
});

const createMenuOption = ([key, value]: [
  key: string,
  value: { shownName: string; relatesToDashboard?: boolean },
]) => ({
  key,
  label: value.shownName,
  relatesToDashboard: value.relatesToDashboard,
});

const disableOption = (
  option: Option,
  foundDim: DimensionFilter,
  action: 'exclude' | 'include',
) => ({
  ...option,
  disabled: foundDim?.[action]?.includes(option?.value),
});

export const GlobalFilter = ({
  dimensions,
  onChange,
  globalFilterTrigger,
  showDimensions = true,
  showSubcategories = true,
  maxFilterCount = undefined,
}: {
  dimensions: DimensionFilter[];
  onChange: (newDimensions: DimensionFilter[]) => void;
  globalFilterTrigger?: string[] | null;
  showDimensions?: boolean;
  showSubcategories?: boolean;
  maxFilterCount?: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenChipMenu, setIsOpenChipMenu] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState<string | null>(null);
  const [activeChip, setActiveChip] = useState<string | null>(null);
  const [currentRadioOption, setCurrentRadioOption] = useState<RadioOptions>(RadioOptions.INCLUDE);
  const filterMenuRef = useRef<HTMLDivElement>(null);
  const { currentAdvertiserId } = useOrganization();
  const skipQuery = {
    skip: !currentAdvertiserId,
  };

  const { data: constants } = useConstantsQuery(null);
  const { data: mediaTypes } = useMediaTypesQuery(null);
  const { data: publishers } = usePublishersQuery(null);
  const { data: audiences } = useAudiencesQuery(currentAdvertiserId, skipQuery);
  const { data: creatives } = useCreativesQuery(currentAdvertiserId, skipQuery);
  const { data: insertionOrders } = useInsertionOrdersQuery(currentAdvertiserId, skipQuery);
  const { data: lineItems } = useLineItemsQuery(currentAdvertiserId, skipQuery);
  const { data: subcategories = {} } = useSubcategoriesQuery(currentAdvertiserId, skipQuery);
  const { data: splits } = useSplitsQuery(currentAdvertiserId, skipQuery);
  const availableCategories = Object.keys(subcategories);

  const allDimensionOptions = (constants && constants.Dimensions) || {};
  const allSubcategoryOptions = (constants && constants.Metrics) || {};

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterMenuRef.current && !filterMenuRef.current.contains(event.target as Node)) {
        setSelectedDimension(null);
        setCurrentRadioOption(RadioOptions.INCLUDE);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setSelectedDimension]);

  useEffect(() => {
    if (globalFilterTrigger) {
      setSelectedDimension('CAMPAIGN');
      setIsOpen(true);
    }
  }, [globalFilterTrigger]);

  const foundDimension = useMemo(
    () => dimensions.find((el) => (el.dimension || el.metric) === selectedDimension),
    [dimensions, selectedDimension],
  );

  const handleOpenChipMenu = (isOpen: boolean) => {
    if (!isOpen) {
      setActiveChip(null);
      setSelectedDimension(null);
    }
    setIsOpenChipMenu(isOpen);
  };

  const handleTagDelete = (dimensionType: string, optionKey: RadioOptions) => {
    const deepCopyDimensions = createDeepCopy(dimensions);
    const foundDimension = deepCopyDimensions.find(
      (item) => (item.dimension || item.metric) === dimensionType,
    );

    if (foundDimension) {
      delete foundDimension[optionKey];
    }
    onChange(
      deepCopyDimensions.filter(
        (dimension) => !!dimension.exclude?.length || !!dimension.include?.length,
      ),
    );
  };

  const handleTagRender = (props: CustomTagProps) => {
    const { label, value } = props!;
    const isActive = activeChip === label;

    return (
      <Dropdown
        trigger={['click']}
        onOpenChange={handleOpenChipMenu}
        open={isOpenChipMenu && isActive}
        dropdownRender={() => (
          <GlobalFilterTemplate
            label={
              (allDimensionOptions[selectedDimension!] || allSubcategoryOptions[selectedDimension!])
                .shownName
            }
            checkboxOptions={disableDuplicatedOption(getDimensionOptions()) as CheckboxOptionType[]}
            selectedOptions={value.dimension[value.radioOption]}
            radioOption={value.radioOption}
            handleDimensionRadioOption={setCurrentRadioOption}
            handleSubmitAction={(data) => addOrUpdateDimension(data, value.radioOption)}
          />
        )}
      >
        <div>
          <Chip
            active={isActive}
            onClick={() => {
              setActiveChip(label as string);
              setSelectedDimension(value.dimension.dimension || value.dimension.metric);
              setIsOpenChipMenu(true);
            }}
            onClose={() =>
              handleTagDelete(
                value.dimension.dimension || value.dimension.metric,
                value.radioOption,
              )
            }
          >
            {label}
          </Chip>
        </div>
      </Dropdown>
    );
  };

  const handleOptionSelect: MenuProps['onClick'] = ({ key }) => {
    setSelectedDimension(key);
    setTimeout(() => setIsOpen(true), 0);
  };

  const menu: MenuProps = {
    items: [],
    onClick: handleOptionSelect,
  };

  if (showDimensions) {
    menu.items?.push({
      key: 'Dimensions',
      label: 'Dimensions',
      type: 'group',
      children: Object.entries(allDimensionOptions)
        .map(createMenuOption)
        .filter((d) => d.relatesToDashboard),
    });
  }

  if (showSubcategories) {
    menu.items?.push({
      key: 'Subcategories',
      label: 'Subcategories',
      type: 'group',
      children: Object.entries(allSubcategoryOptions)
        .map(createMenuOption)
        .filter(({ key }) => availableCategories.includes(key)),
    });
  }

  const disableDuplicatedOption = (options: Option[] | undefined) =>
    foundDimension
      ? options?.map((option) => {
          if (currentRadioOption === RadioOptions.INCLUDE) {
            return disableOption(option, foundDimension, RadioOptions.EXCLUDE);
          } else if (currentRadioOption === RadioOptions.EXCLUDE) {
            return disableOption(option, foundDimension, RadioOptions.INCLUDE);
          }
        })
      : options;

  const getDimensionOptions = () => {
    switch (selectedDimension) {
      case 'PUBLISHER':
        return publishers?.map(createDimensionOption);
      case 'CAMPAIGN':
        return insertionOrders?.map((io) => io.displayName).map(createDimensionOption);
      case 'CREATIVE':
        return creatives?.map(createDimensionOption);
      case 'AUDIENCE':
        return audiences?.map(createDimensionOption);
      case 'LINE_ITEM':
        return lineItems?.map(createDimensionOption);
      case 'MEDIA_TYPE':
        return mediaTypes?.map(({ name }) => createDimensionOption(name));
      case 'SPLIT':
        return splits?.map(createDimensionOption);
      default:
        return selectedDimension && availableCategories.includes(selectedDimension)
          ? subcategories[selectedDimension]?.map(createDimensionOption)
          : [];
    }
  };

  const addOrUpdateDimension = (
    data: {
      radioOption: RadioOptions;
      checkboxOptions: CheckboxOptionType[];
    },
    previousRadioOption?: RadioOptions,
  ) => {
    const deepCopyDimensions = createDeepCopy(dimensions);

    const existingDimension = deepCopyDimensions.find(
      (item) => (item.dimension || item.metric) === selectedDimension,
    );

    if (existingDimension) {
      existingDimension[data.radioOption] = data.checkboxOptions as unknown as string[];

      if (previousRadioOption !== data.radioOption) {
        delete existingDimension[previousRadioOption!];
      }
      onChange(deepCopyDimensions);
      setIsOpenChipMenu(false);
      setActiveChip(null);
    } else {
      const newDimension = {
        ...(selectedDimension && visibleSubcategories.includes(selectedDimension)
          ? { metric: selectedDimension }
          : { dimension: selectedDimension }),
        ...{ [data.radioOption as string]: data.checkboxOptions },
      } as DimensionFilter;

      onChange([...deepCopyDimensions, newDimension]);
    }
    setIsOpen(false);
    setSelectedDimension(null);
  };

  const selectedOptions = foundDimension ? foundDimension[currentRadioOption] : [];
  const allOptions = { ...allDimensionOptions, ...allSubcategoryOptions };

  return (
    <Flex className="flex-1">
      <Dropdown
        trigger={['click']}
        onOpenChange={(menuOpen) => setIsOpen(menuOpen)}
        open={isOpen}
        menu={menu}
        dropdownRender={(menu) => (
          <div>
            {selectedDimension ? (
              <div ref={filterMenuRef}>
                <GlobalFilterTemplate
                  label={
                    (
                      allDimensionOptions[selectedDimension] ||
                      allSubcategoryOptions[selectedDimension]
                    ).shownName
                  }
                  checkboxOptions={
                    disableDuplicatedOption(getDimensionOptions()) as CheckboxOptionType[]
                  }
                  selectedOptions={selectedOptions as unknown as CheckboxOptionType[]}
                  radioOption={currentRadioOption}
                  handleDimensionRadioOption={setCurrentRadioOption}
                  handleSubmitAction={addOrUpdateDimension}
                />
              </div>
            ) : (
              <div className="filter-dropdown-options">{menu}</div>
            )}
          </div>
        )}
      >
        <Flex gap="small" align="center" className={`filter-button ${isOpen ? 'active' : ''}`}>
          <FilterIcon className="h-5 w-5" /> Filter
        </Flex>
      </Dropdown>
      <Select
        className="filter-select"
        allowClear={{
          clearIcon: (
            <div className="filter-clear cursor-pointer text-base-sm font-medium leading-tight underline">
              Clear all
            </div>
          ),
        }}
        open={false}
        mode="multiple"
        maxTagCount={maxFilterCount}
        value={convertDimensionsToTags(allOptions, dimensions)}
        tagRender={handleTagRender}
        onClear={() => onChange([])}
      />
    </Flex>
  );
};
