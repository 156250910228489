import { DatePicker as AntdDatePicker, Flex } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { ChevronDown, ChevronUp } from 'feather-icons-react';
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
import { BaseInfo } from 'rc-picker/lib/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

import { Button } from '../button';
import {
  useDateRangePresets,
  useDefaultDateRangePresets,
  UseDefaultDateRangePresetsOptions,
} from './dateRangePresets';

export type DateRange = {
  startDate: RangeValueType<Dayjs>[0];
  endDate: RangeValueType<Dayjs>[1];
  period: string;
};
export type DateRangeValue = RangeValueType<Dayjs> | null;

const AntdDateRangePicker = AntdDatePicker.RangePicker;
const CUSTOM = 'CUSTOM';

export const DateRangePicker = ({
  format = 'MMM D, YYYY',
  onChange = () => {},
  value = null,
  timeSlice,
  disabled,
  minDate,
  maxDate,
  className,
  placement,
  size,
  status,
  defaultValue,
  presetOptions,
}: {
  value?: DateRangeValue;
  defaultValue?: DateRangeValue;
  format?: string;
  timeSlice?: string;
  disabled?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  className?: string;
  placement?: RangePickerProps['placement'];
  size?: RangePickerProps['size'];
  status?: RangePickerProps['status'];
  onChange?: ({ startDate, endDate, period }: DateRange) => void;
  presetOptions?: UseDefaultDateRangePresetsOptions;
}) => {
  const { presets: defaultPresets, isCampaignsLoaded } = useDefaultDateRangePresets();
  const presets = useDateRangePresets(minDate, maxDate, presetOptions);
  const { data: constants } = useConstantsQuery(null);
  const timeSlices = useMemo(() => (constants && constants.TimeSlice) || {}, [constants]);

  if (!defaultValue) {
    defaultValue =
      presets.find((preset) => preset.label === timeSlices.CAMPAIGN_TO_DATE)?.value ||
      presets.find((preset) => preset.label === timeSlices.TODAY)?.value;
  }

  const findTimeSlice = useCallback(
    (dateRangeValue: DateRangeValue): string => {
      if (!dateRangeValue || !dateRangeValue[0] || !dateRangeValue[1]) {
        return CUSTOM;
      }
      const format = 'YYYY-MM-DD';
      const startDate = dateRangeValue[0]?.format(format);
      const endDate = dateRangeValue[1]?.format(format);
      const preset = defaultPresets.find((preset) => {
        return (
          preset.value[0]?.format(format) === startDate &&
          preset.value[1]?.format(format) === endDate
        );
      });

      if (preset) {
        const timeSliceKey = Object.keys(timeSlices).find(
          (key) => timeSlices[key] === preset.label,
        );

        if (timeSliceKey) {
          return timeSliceKey;
        }
      }

      return CUSTOM;
    },
    [defaultPresets, timeSlices],
  );

  const isCustom = useCallback(
    (dateRangeValue: DateRangeValue) => findTimeSlice(dateRangeValue) === CUSTOM,
    [findTimeSlice],
  );

  const [initialValue, setInitialValue] = useState<DateRangeValue>(
    value ? [...value] : defaultValue || value,
  );
  const [_value, setValue] = useState<DateRangeValue>(initialValue);
  const [startDate, setStartDate] = useState<Dayjs | null | undefined>(_value ? _value[0] : null);
  const [endDate, setEndDate] = useState<Dayjs | null | undefined>(_value ? _value[1] : null);
  const [isOpen, setIsOpen] = useState(false);
  const [startDateStatus, setStartDateStatus] = useState<'error'>();
  const [endDateStatus, setEndDateStatus] = useState<'error'>();

  const onClickOutside = (e: MouseEvent) => {
    const closest = (e.target as HTMLElement)?.closest('.ctv-picker-root');

    if (!closest && isOpen) {
      handleCancel();
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onClickOutside);
    } else {
      document.removeEventListener('click', onClickOutside);
    }

    return () => {
      document.removeEventListener('click', onClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!value &&
      isCampaignsLoaded &&
      defaultValue &&
      defaultValue[0] &&
      defaultValue[1]
    ) {
      setInitialValue(defaultValue);
      setValue(defaultValue);
      setStartDate(defaultValue[0]);
      setEndDate(defaultValue[1]);
      onChange({
        startDate: defaultValue[0],
        endDate: defaultValue[1],
        period: findTimeSlice(defaultValue),
      });
    }
  }, [value, defaultValue, onChange, findTimeSlice, isCampaignsLoaded]);

  const handleChange = (dates: DateRangeValue) => {
    if (!isOpen && !dates) {
      if (_value) {
        onChange({ startDate, endDate, period: timeSlices.CUSTOM });
      }
      setValue(null);
      setInitialValue(null);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const focusPickerInput = (range: 'start' | 'end' = 'start') => {
    const inputs = document.querySelectorAll<HTMLInputElement>(
      '.ctv-picker-root .ant-picker-input input',
    );

    inputs[range === 'start' ? 0 : 1].focus();
  };

  const onCalendarChange = (
    dates: RangeValueType<Dayjs>,
    dateStrings: [string, string],
    info: BaseInfo,
  ) => {
    let _dates: DateRangeValue = [...dates];
    const [start, end] = _dates;

    if (start && end && start.isAfter(end)) {
      _dates = [end, start];
      info.range = 'start';
    }
    setValue(_dates);
    setStartDate(_dates[0]);
    setEndDate(_dates[1]);
    setStartDateStatus(undefined);
    setEndDateStatus(undefined);
    focusPickerInput(info.range === 'start' ? 'end' : 'start');
  };

  const handleApply = () => {
    if (!startDate || !endDate) {
      if (!startDate) setStartDateStatus('error');
      if (!endDate) setEndDateStatus('error');

      return;
    }
    const adjustedEndDate = endDate.endOf('day');

    const dateRange = {
      startDate,
      endDate: adjustedEndDate,
      period: findTimeSlice([startDate, adjustedEndDate]),
    };

    setInitialValue([startDate, adjustedEndDate]);
    setValue([startDate, adjustedEndDate]);
    setEndDate(adjustedEndDate);
    handleClose();
    onChange(dateRange);
  };

  const handleCancel = () => {
    setValue(initialValue);
    setStartDate(initialValue ? initialValue[0] : null);
    setEndDate(initialValue ? initialValue[1] : null);
    handleClose();
    setStartDateStatus(undefined);
    setEndDateStatus(undefined);
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    let start = date;
    let end = endDate;

    if (date && date.isAfter(endDate)) {
      start = endDate || null;
      end = date;
      setEndDate(end);
    }
    setStartDateStatus(start ? undefined : 'error');
    setStartDate(start);
    setValue([start, end]);
    if (!start) {
      focusPickerInput('start');
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    let end = date ? date.endOf('day') : null;
    let start = startDate;

    if (date && date.isBefore(startDate)) {
      end = startDate ? startDate.endOf('day') : null;
      start = date;
      setStartDate(start);
    }
    setEndDateStatus(end ? undefined : 'error');
    setEndDate(end);
    setValue([start, end]);
    if (!end) {
      focusPickerInput(start ? 'end' : 'start');
    }
  };

  const handleStartDateBlur = (e: React.FocusEvent<HTMLElement>) => {
    const targetValue = e.target.getAttribute('value');

    if (!dayjs(targetValue).isValid()) {
      setStartDateStatus('error');
      setStartDate(null);

      return;
    }
    handleStartDateChange(dayjs(targetValue));
  };

  const handleEndDateBlur = (e: React.FocusEvent<HTMLElement>) => {
    const targetValue = e.target.getAttribute('value');

    if (!dayjs(targetValue).isValid()) {
      setEndDateStatus('error');
      setEndDate(null);

      return;
    }
    handleEndDateChange(dayjs(targetValue));
  };

  const handleKey = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') {
      handleCancel();
      e.currentTarget?.blur();
    }
  };

  const shouldDisableApply = () => {
    if (!_value || !_value[0] || !_value[1]) {
      return true;
    }
    if (startDate?.isSame(initialValue?.[0]) && endDate?.isSame(initialValue?.[1])) {
      return true;
    }

    return false;
  };

  const placeholder = ((dates: DateRangeValue): [string, string] => {
    return [dates?.[0]?.format(format) || format, dates?.[1]?.format(format) || format];
  })(_value || initialValue);

  const footer = () => (
    <Flex justify="space-between" className="py-3">
      <Flex gap="small">
        <AntdDatePicker
          open={false}
          format={format}
          placeholder={placeholder[0]}
          value={startDate}
          onChange={handleStartDateChange}
          status={startDateStatus}
          suffixIcon={null}
          onBlur={handleStartDateBlur}
          className={ctvTwMerge(classNames('ctv-picker ctv-picker-start-date'), className)}
        />
        -
        <AntdDatePicker
          open={false}
          format={format}
          placeholder={placeholder[1]}
          value={endDate}
          onChange={handleEndDateChange}
          status={endDateStatus}
          suffixIcon={null}
          onBlur={handleEndDateBlur}
          className={ctvTwMerge(classNames('ctv-picker ctv-picker-end-date'), className)}
        />
      </Flex>
      <Flex gap="small">
        <Button onClick={handleCancel} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleApply}
          disabled={shouldDisableApply()}
          className="ctv-picker-apply-btn"
        >
          Apply
        </Button>
      </Flex>
    </Flex>
  );

  const timeSliceLabel =
    timeSlice && !isOpen ? `[${timeSlices[timeSlice]}]` : `[${timeSlices[findTimeSlice(_value)]}]`;
  const showTimeSliceLabel = !isCustom(_value) || (timeSlice && !isOpen);

  return (
    <AntdDateRangePicker
      disabled={disabled}
      onChange={handleChange}
      format={showTimeSliceLabel ? timeSliceLabel : format}
      className={ctvTwMerge(
        classNames(`ctv-picker ${showTimeSliceLabel && 'ctv-picker-preset'}`),
        className,
      )}
      rootClassName="ctv-picker-root"
      placement={placement}
      placeholder={placeholder}
      separator={!showTimeSliceLabel && '-'}
      suffixIcon={isOpen ? <ChevronUp /> : <ChevronDown />}
      allowEmpty={[false, false]}
      allowClear={false}
      value={_value}
      defaultValue={defaultValue}
      minDate={minDate}
      maxDate={maxDate}
      presets={presets}
      open={isOpen}
      onCalendarChange={onCalendarChange}
      renderExtraFooter={footer}
      onClick={handleOpen}
      size={size}
      status={status}
      superNextIcon={null}
      superPrevIcon={null}
      onKeyDown={handleKey}
    />
  );
};
