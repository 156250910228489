import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from 'react';

import { Checkbox } from 'components/common';

export type CheckboxOption = {
  label: string;
  value: string;
  disabled?: boolean;
  subOptions?: CheckboxOption[];
};

type NestedCheckboxGroupProps = {
  options: CheckboxOption[];
  value: string[];
  onChange: (value: string[]) => void;
};

const getValue = ({ value }: { value: string }) => value;

export const NestedCheckBoxGroup = ({
  options,
  value = [],
  onChange,
}: NestedCheckboxGroupProps) => {
  const [indeterminateMap, setIndeterminateMap] = useState<Record<string, boolean>>({});

  const isAllSelected =
    value.length ===
    options.flatMap(({ subOptions }) => subOptions?.map(({ value }) => value) || []).length;

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    const data = e.target.checked
      ? options.flatMap(({ subOptions }) => subOptions?.map(({ value }) => value) || [])
      : [];

    onChange(data);
  };

  const handleAgencyChange = (agencyValue: string, subOptions: CheckboxOption[]) => {
    const allSubValues = subOptions.map(({ value }) => value);
    const subValueSelected = (subValue: string) => value.includes(subValue);
    const isSelected = allSubValues.every(subValueSelected);

    const newValues = isSelected
      ? value.filter((val) => !allSubValues.includes(val))
      : [...new Set([...value, ...allSubValues])];

    setIndeterminateMap((prev) => ({ ...prev, [agencyValue]: false }));

    onChange(newValues);
  };

  const handleAdvertiserChange = (
    advertiserValue: string,
    agencyValue: string,
    subOptions: CheckboxOption[],
  ) => {
    const newValues = value?.includes(advertiserValue)
      ? value.filter((val) => val !== advertiserValue)
      : [...value, advertiserValue];

    const allSubValues = subOptions.map(getValue);
    const subValueSelected = (subValue: string) => newValues.includes(subValue);
    const isSelected = allSubValues.every(subValueSelected);
    const isPartiallySelected = allSubValues.some(subValueSelected);

    setIndeterminateMap((prev) => ({
      ...prev,
      [agencyValue]: isPartiallySelected && !isSelected,
    }));

    onChange(newValues);
  };

  const hasAllSuboptions = (subOptions: CheckboxOption[], value: string[]) =>
    subOptions.length > 0 && subOptions?.every((subOption) => value.includes(subOption.value));

  return (
    <div>
      <Checkbox
        indeterminate={!isAllSelected && value.length > 0}
        checked={isAllSelected}
        onChange={handleSelectAll}
        className="mb-2"
      >
        All Agencies
      </Checkbox>

      {options.map(({ label: agencyLabel, value: agencyValue, subOptions = [] }) => (
        <div key={agencyValue} className="ml-6 flex flex-col">
          <Checkbox
            indeterminate={indeterminateMap[agencyValue]}
            checked={hasAllSuboptions(subOptions, value)}
            onChange={() => handleAgencyChange(agencyValue, subOptions || [])}
            className="mb-2"
          >
            {agencyLabel}
          </Checkbox>

          <div className="ml-6 flex flex-col gap-2 mb-2">
            {subOptions?.map(({ label, value: advertiserValue, disabled = false }) => (
              <Checkbox
                className=""
                key={advertiserValue}
                checked={value.includes(advertiserValue)}
                disabled={disabled}
                onChange={() => handleAdvertiserChange(advertiserValue, agencyValue, subOptions)}
              >
                {label}
              </Checkbox>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
