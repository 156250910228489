/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from 'utils';

import { authStorage } from '../auth/authStorage';
import { publisherApi } from './publisherApi';
import {
  CreateUpdatePublisherRequest,
  MembersPagingRequestValue,
  MembersPagingResponse,
  Publisher,
} from './publisherApiTypes';
import {
  getPublishersSuccess,
  getPublishersFailure,
  getPublishersStart,
  createPublisherSuccess,
  createPublisherFailure,
  createPublisherStart,
  updatePublisherStart,
  deletePublisherStart,
  uploadPublisherFileSuccess,
  uploadPublisherFileFailure,
  uploadPublisherFileStart,
} from './publisherSlice';

function* getPublishersFlow(action: PayloadAction<MembersPagingRequestValue>) {
  try {
    const authToken: string = yield call(authStorage.getIdToken);
    const response: MembersPagingResponse = yield call(publisherApi.getPublishers, action.payload);
    const publishers = updateImageUrlsAndFormatTimestamps(response.data, authToken);

    yield put(getPublishersSuccess(publishers));
  } catch (error) {
    yield put(getPublishersFailure(getErrorMessage(error)));
  }
}

function* createPublisherFlow(action: PayloadAction<CreateUpdatePublisherRequest>) {
  try {
    const response: Publisher = yield call(publisherApi.createPublisher, action.payload);

    yield put(createPublisherSuccess(response));
  } catch (error) {
    yield put(createPublisherFailure(getErrorMessage(error)));
  }
}

function* updatePublisherFlow(action: PayloadAction<CreateUpdatePublisherRequest>) {
  try {
    const response: Publisher = yield call(publisherApi.updatePublisher, action.payload);

    yield put(createPublisherSuccess(response));
  } catch (error) {
    yield put(createPublisherFailure(getErrorMessage(error)));
  }
}

function* deletePublisherFlow(action: PayloadAction<Publisher>) {
  try {
    const response: Publisher = yield call(publisherApi.deletePublisher, action.payload.id);

    yield put(createPublisherSuccess(response));
  } catch (error) {
    yield put(createPublisherFailure(getErrorMessage(error)));
  }
}

function* uploadPublisherFileFlow(action: PayloadAction<any>) {
  try {
    const response: Publisher = yield call(publisherApi.uploadPublisherFile, action.payload);

    yield put(uploadPublisherFileSuccess(response));
  } catch (error) {
    yield put(uploadPublisherFileFailure(getErrorMessage(error)));
  }
}

function updateImageUrlsAndFormatTimestamps(
  publishers: Publisher[],
  authToken: string,
): Publisher[] {
  return publishers.map((publisher) => ({
    ...publisher,
    icon: publisher.icon ? `${publisher.icon}&idToken=${authToken}` : publisher.icon,
    creationDate: publisher.creationDate
      ? format(new Date(publisher.creationDate), 'MMM dd, yyyy')
      : publisher.creationDate,
  }));
}

export function* publisherSagas() {
  yield all([takeLatest(getPublishersStart.type, getPublishersFlow)]);
  yield all([takeLatest(createPublisherStart.type, createPublisherFlow)]);
  yield all([takeLatest(updatePublisherStart.type, updatePublisherFlow)]);
  yield all([takeLatest(deletePublisherStart.type, deletePublisherFlow)]);
  yield all([takeLatest(uploadPublisherFileStart.type, uploadPublisherFileFlow)]);
}
