/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import {
  CreateUpdatePublisherRequest,
  MembersPagingRequestValue,
  Publisher,
  PublisherState,
} from './publisherApiTypes';

export const initialState: PublisherState = {
  isLoading: false,
  refreshFlag: false,
  error: null,
  publishers: null,
  createdPublisher: null,
};

export const publisherSlice = createSlice({
  name: 'publisher',
  initialState,
  reducers: {
    getPublishersStart(state: PublisherState, action: PayloadAction<MembersPagingRequestValue>) {
      state.isLoading = true;
    },
    getPublishersSuccess(state: PublisherState, action: PayloadAction<Publisher[]>) {
      state.isLoading = false;
      state.publishers = action.payload;
      state.error = null;
    },
    getPublishersFailure(state: PublisherState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    createPublisherStart(
      state: PublisherState,
      action: PayloadAction<CreateUpdatePublisherRequest>,
    ) {
      state.refreshFlag = true;
    },
    createPublisherSuccess(state: PublisherState, action: PayloadAction<Publisher>) {
      state.refreshFlag = false;
      state.error = null;
      state.createdPublisher = action.payload;
    },
    createPublisherFailure(state: PublisherState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.refreshFlag = false;
    },
    updatePublisherStart(
      state: PublisherState,
      action: PayloadAction<CreateUpdatePublisherRequest>,
    ) {
      state.refreshFlag = true;
    },
    updatePublisherSuccess(state: PublisherState, action: PayloadAction<Publisher[]>) {
      state.refreshFlag = false;
      state.error = null;
    },
    updatePublisherFailure(state: PublisherState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.refreshFlag = false;
    },
    deletePublisherStart(state: PublisherState, action: PayloadAction<Publisher>) {
      state.refreshFlag = true;
    },
    deletePublisherSuccess(state: PublisherState, action: PayloadAction<Publisher[]>) {
      state.refreshFlag = false;
      state.error = null;
    },
    deletePublisherFailure(state: PublisherState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.refreshFlag = false;
    },
    uploadPublisherFileStart(state: PublisherState, action: PayloadAction<any>) {
      state.refreshFlag = true;
    },
    uploadPublisherFileSuccess(state: PublisherState, action: PayloadAction<any>) {
      state.refreshFlag = false;
      state.error = null;
    },
    uploadPublisherFileFailure(state: PublisherState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.refreshFlag = false;
    },
  },
});

export const getPublisherState = ({ publisher }: AppState) => publisher;

export const {
  getPublishersStart,
  getPublishersSuccess,
  getPublishersFailure,
  createPublisherStart,
  createPublisherSuccess,
  createPublisherFailure,
  updatePublisherStart,
  updatePublisherSuccess,
  updatePublisherFailure,
  deletePublisherStart,
  deletePublisherSuccess,
  deletePublisherFailure,
  uploadPublisherFileStart,
  uploadPublisherFileSuccess,
  uploadPublisherFileFailure,
} = publisherSlice.actions;
export const publisher = publisherSlice.reducer;
