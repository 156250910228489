import { RoleHierarchy } from 'modules/misc/miscApiTypes';
import { Agency } from 'modules/organization/organizationApiTypes';
import { InviteAgency, RoleKey, UserRoles } from 'modules/user/userApiTypes';

import { CheckboxOption } from './NestedCheckBoxGroup';

export const mapAgenciesToCheckboxOptions = (agencies: Agency[]) =>
  agencies.map(({ name, id, advertisers }) => ({
    label: name,
    value: id,
    subOptions: advertisers.map(({ name, id }) => ({ label: name, value: id })),
  }));

export const toggleDisabled = (subOption: CheckboxOption, disabled: boolean) => ({
  ...subOption,
  disabled,
});

export const mapValuesToInviteRequest = (
  agencies: Agency[],
  selectedAdvertisers: string[],
): InviteAgency[] =>
  agencies
    .map(({ id: agencyId, advertisers }) => {
      const advertiserIds = advertisers
        .map(({ id }) => id)
        .filter((advertiserId) => selectedAdvertisers.includes(advertiserId));

      return advertiserIds.length > 0 ? { agencyId, advertiserIds } : null;
    })
    .filter(Boolean) as InviteAgency[];

export const mapAgenciesToInviteRequest = (
  agencies: Agency[],
  selectedAgencies: string[],
): InviteAgency[] =>
  agencies
    .filter(({ id }) => selectedAgencies.includes(id))
    .map(({ id: agencyId }) => ({ agencyId, advertiserIds: [] }));

export const mergeAgencies = (
  inviteAgencies: InviteAgency[],
  futureAgencies: InviteAgency[],
): InviteAgency[] => {
  const futureMap = new Map(futureAgencies.map((agency) => [agency.agencyId, agency]));

  return inviteAgencies
    .map((agency) => (futureMap.has(agency.agencyId) ? { ...agency, advertiserIds: [] } : agency))
    .concat(
      futureAgencies.filter(
        (agency) => !inviteAgencies.some((i) => i.agencyId === agency.agencyId),
      ),
    );
};

export const getIsShowingAgencies = (role: string) =>
  role === UserRoles.ADMIN.value || role === UserRoles.LIMITED_ACCESS.value;

export const atLeastOneOf = (values: {
  role: string;
  advertisers: (string | undefined)[];
  withFutureAdvertisers: (string | undefined)[];
}) =>
  getIsShowingAgencies(values.role)
    ? values.advertisers?.length > 0 || values.withFutureAdvertisers?.length > 0
    : true;

export const filterUserRoles = (roleHierarchy: RoleHierarchy = {}, role?: string) =>
  roleHierarchy[role as string].map((key) => UserRoles[key as RoleKey]);
