/* eslint-disable camelcase */
import { LoadingOutlined } from '@ant-design/icons';
import { formatNumber } from 'utils';

import { ReactComponent as IconChevronSort } from 'assets/images/icons/chevron-selector-vertical.svg';

import { Dimensions, Metrics } from 'components/Incrementality/incrementalityTypes';
import { Chart, ChartOption } from 'components/common';

import { Incrementality, sortDirections } from 'modules/performance/performanceApiTypes';
import { usePerformance } from 'modules/performance/usePerformance';

const getTooltipContent = ({
  incrementality_lift,
  exposed_reach,
  exposed_conversions,
  exposed_conversions_rate,
  control_conversions_rate,
  control_reach,
  control_conversions,
}: Incrementality) => `
    <div style="font-size: 18px; font-weight: bold; line-height: normal;">
      Incremental lift: ${formatNumber(incrementality_lift / 100)}x
    </div>
    <div style="width: 100%; height: 70px; padding-left: 12px; border-left: 2px solid #6d49d4; margin-top: 8px;">
      <div style="font-size: 14px; font-weight: normal; line-height: 1.5;">
        Exposed count: ${exposed_reach.toLocaleString()}
      </div>
      <div style="font-size: 14px; font-weight: normal;  line-height: 1.5;">
        Exposed conversions: ${exposed_conversions.toLocaleString()}
      </div>
      <div style="font-size: 14px; font-weight: normal;  line-height: 1.5;">
        Exposed conversion rate: ${formatNumber(exposed_conversions_rate)}%
      </div>
    </div>
    <div style="width: 100%; height: 70px; padding-left: 12px; border-left: 2px solid #cacbcd; margin-top: 8px;">
      <div style="font-size: 14px; font-weight: normal;  line-height: 1.5;">
        Controlled count: ${control_reach.toLocaleString()}
      </div>
      <div style="font-size: 14px; font-weight: normal;  line-height: 1.5;">
        Controlled conversions: ${control_conversions.toLocaleString()}
      </div>
      <div style="font-size: 14px; font-weight: normal; line-height: 1.5;">
        Controlled conversion rate: ${control_conversions_rate}%
      </div>
    </div>
`;

export const IncrementalityTable = () => {
  const {
    incrementalityFilters,
    pickIncrementalityOrder,
    incrementalityOrder,
    incrementalities,
    isIncrementalityLoading,
  } = usePerformance();

  const chartOption = (allIncrementalities: Incrementality[]) => ({
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      axisPointer: {
        type: 'none',
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      formatter: (params: any) => {
        const index = params[0].dataIndex;
        const item = allIncrementalities[index];

        return getTooltipContent(item);
      },
    },
    legend: {},
    grid: {
      left: '-1px',
      top: 0,
      right: '4%',
      bottom: 0,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      data: allIncrementalities?.map((incrementality) => incrementality.name),
      inverse: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        color: '#6D49D4',
        data: allIncrementalities?.map((incrementality) => incrementality.exposed_conversions_rate),
        barGap: '0%',
        barWidth: incrementalityFilters?.dimension === Dimensions.OVERALL.value ? 64 : 25,
        itemStyle: {
          color: '#6D49D4',
        },
        label: {
          show: true,
          position: 'right',
          color: '#6D49D4',
          formatter: (value: { data: number }) => formatNumber(value.data) + '%',
        },
      },
      {
        type: 'bar',
        color: '#CACBCD',
        barWidth: incrementalityFilters?.dimension === Dimensions.OVERALL.value ? 64 : 25,
        data: allIncrementalities?.map((incrementality) => incrementality.control_conversions_rate),
        itemStyle: {
          color: '#CACBCD',
        },
        label: {
          show: true,
          position: 'right',
          color: '#16171A',
          formatter: (value: { data: number }) => value.data + '%',
        },
      },
    ],
  });

  const createOption = (data: Incrementality[]): ChartOption => {
    return {
      legend: {},
      grid: {
        left: '0%',
        right: '4%',
        bottom: '0%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: string) => (!value ? '' : `${value}%`),
          show: true,
        },
      },
      yAxis: {
        type: 'category',
        data: [''],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      tooltip: {
        axisPointer: {
          type: 'none',
        },
        show: false,
      },
      series: [
        {
          type: 'bar',
          color: '#6D49D4',
          data: data?.map((el) => el.exposed_conversions_rate),
          label: {
            show: false,
          },
          barWidth: '-1px',
          barGap: '0%',
          itemStyle: {
            color: '#6D49D4',
            opacity: 0,
          },
        },
        {
          type: 'bar',
          color: '#CACBCD',
          data: data?.map((el) => el.control_conversions_rate),
          label: {
            show: false,
          },
          barWidth: '-1px',
          itemStyle: {
            color: '#CACBCD',
            opacity: 0,
          },
        },
      ],
    };
  };

  const BAR_HEIGHT = 80;
  const height = incrementalities && BAR_HEIGHT * incrementalities.length;

  const onSort = () => {
    pickIncrementalityOrder(
      incrementalityOrder === sortDirections.ascend
        ? sortDirections.descend
        : sortDirections.ascend,
    );
  };

  return (
    <div className="px-2 w-full">
      <div className="header bg-primary-gray-50 rounded">
        <div className="w-64 h-8 px-3 py-2 border-r border-primary-gray-100 justify-start items-center gap-1 inline-flex">
          <div className="justify-start items-center gap-1 flex">
            <div className="text-primary-gray-500 text-base-xs font-medium">
              {incrementalityFilters?.dimension &&
                Dimensions[incrementalityFilters.dimension as keyof typeof Dimensions]?.label}
            </div>
            <IconChevronSort
              width={20}
              height={20}
              className="text-primary-gray-500 cursor-pointer"
              onClick={onSort}
            />
          </div>
        </div>
        <div className="h-8 px-3 py-2 justify-start items-center gap-1 inline-flex">
          <div className="justify-start items-center gap-1 flex">
            <div className="text-primary-gray-500 text-base-xs font-medium">
              Conversion rate (
              {incrementalityFilters?.metric &&
                Metrics[incrementalityFilters.metric as keyof typeof Metrics]?.label}
              )
            </div>
          </div>
        </div>
      </div>
      {isIncrementalityLoading ? (
        <div className="flex h-128 justify-center text-primary-gray-600">
          <LoadingOutlined spin />
        </div>
      ) : (
        <>
          <div className="h-128 inline-flex w-full justify-between align-middle border-b border-primary-gray-100 overflow-auto">
            <div
              className={`${incrementalityFilters?.dimension === Dimensions.OVERALL.value ? 'flex items-center' : ''} border-r border-primary-gray-100`}
            >
              {incrementalities?.map((incrementality, index) => (
                <div
                  key={index}
                  className="w-[255px] h-20 px-3 flex items-center text-base-sm font-normal leading-tight break-all"
                >
                  {incrementalityFilters?.dimension &&
                  incrementalityFilters?.dimension === Dimensions.OVERALL.value
                    ? Dimensions[incrementalityFilters.dimension as keyof typeof Dimensions]?.label
                    : incrementality.name}
                </div>
              ))}
            </div>
            <div
              className={`${incrementalityFilters?.dimension === Dimensions.OVERALL.value ? 'flex items-center' : ''} w-full`}
            >
              <Chart
                option={chartOption(incrementalities!) as ChartOption}
                className="border-l border-primary-gray-100"
                style={{
                  height: height || 0,
                  width: '100%',
                }}
              />
            </div>
          </div>
          <div className="mr-5 ml-[256px] relative">
            <Chart
              style={{ height: '20px' }}
              option={createOption(incrementalities!)}
              {...(incrementalities &&
                incrementalities.length > 1 && {
                  style: { height: '30px' },
                })}
            />
          </div>
        </>
      )}
    </div>
  );
};
