/* eslint-disable no-console */
import {
  SignInInput,
  signIn,
  getCurrentUser,
  fetchAuthSession,
  signOut,
  fetchUserAttributes,
  signUp,
  confirmSignIn,
  resetPassword,
  confirmResetPassword,
  updatePassword,
} from 'aws-amplify/auth';

import NotificationService from 'modules/notifications/notificationService';

import {
  ChangePasswordPayload,
  CreateNewPasswordPayload,
  ForgotPasswordPayload,
  SignupPayload,
} from './types';

const login = async (payload: SignInInput) => {
  const signInOutput = await signIn(payload);

  return signInOutput;
};

const forgotPassword = async (payload: ForgotPasswordPayload) => {
  const response = await resetPassword(payload);

  return response;
};

const createNewPassword = async (payload: CreateNewPasswordPayload) => {
  const response = await confirmResetPassword(payload);

  return response;
};

const changePassword = async (payload: ChangePasswordPayload) => {
  try {
    const response = await updatePassword(payload);

    NotificationService.success('Password changed successfully');

    return response;
  } catch (ex) {
    NotificationService.error('Changing password has failed. Please try again later');
  }
};

const signup = async (payload: SignupPayload) => {
  const signupOutput = await signUp({
    username: payload.username,
    password: payload.password,
    options: {
      userAttributes: {
        ['custom:full_name']: payload.fullname,
      },
    },
  });

  return signupOutput;
};

const confirmSignin = async (signupPayload: SignupPayload) => {
  const result = await confirmSignIn({
    challengeResponse: signupPayload.password,
  });

  return result;
};

const getUser = async () => {
  try {
    const user = await getCurrentUser();

    return user;
  } catch (ex) {
    handleExpiredError();
  }
};

const fetchAttributes = async () => {
  try {
    const attributes = await fetchUserAttributes();

    return attributes;
  } catch (ex) {
    handleExpiredError();
  }
};

const getSession = async () => {
  try {
    const session = await fetchAuthSession();

    return session;
  } catch (ex) {
    handleExpiredError();
  }
};

const logout = async () => {
  await signOut();
};

const handleExpiredError = () => {
  localStorage.clear();
  NotificationService.info('Your access permissions have changed. Please log in again');
};

export const authApi = {
  login,
  logout,
  signup,
  getUser,
  getSession,
  confirmSignin,
  forgotPassword,
  createNewPassword,
  changePassword,
  fetchAttributes,
};
