import dayjs from 'dayjs';
// eslint-disable-next-line import/named
import { object, string, array, InferType, boolean, mixed, number } from 'yup';

import { PerformanceReport } from 'modules/report/reportApiTypes';

export const HOURS = {
  0: '00:00 AM',
  1: '1:00 AM',
  2: '2:00 AM',
  3: '3:00 AM',
  4: '4:00 AM',
  5: '5:00 AM',
  6: '6:00 AM',
  7: '7:00 AM',
  8: '8:00 AM',
  9: '9:00 AM',
  10: '10:00 AM',
  11: '11:00 AM',
  12: '12:00 PM',
  13: '1:00 PM',
  14: '2:00 PM',
  15: '3:00 PM',
  16: '4:00 PM',
  17: '5:00 PM',
  18: '6:00 PM',
  19: '7:00 PM',
  20: '8:00 PM',
  21: '9:00 PM',
  22: '10:00 PM',
  23: '11:00 PM',
};

export const performanceReportSchema = object().shape({
  reportType: string().trim().required(),
  name: string().required('Report name is required'),
  dateRange: object().shape({
    startDate: mixed<dayjs.Dayjs>().nullable(),
    endDate: mixed<dayjs.Dayjs>().nullable(),
    period: string(),
  }),
  showUniques: boolean(),
  methodology: string().required(),
  conversionWindow: number().required(),
  interval: string().required(),
  dimensions: array().required(),
  dimensionFilters: array(
    object().shape({
      metric: string(),
      value: string().optional(),
      include: array(string()).nullable(),
      exclude: array(string()).nullable(),
    }),
  ),
  metrics: array().required().min(1, 'At least one metric is required').required(),
  reportFormat: string().required(),
  isScheduled: boolean(),
  emails: array().when('isScheduled', {
    is: true,
    then: (schema) =>
      schema.min(1, 'At least one email is required').required('Email list is required'),
    otherwise: (schema) => schema,
  }),
  cadenceType: string().required(),
  dailyCadence: string().required(),
  weeklyCadence: string(),
  monthlyCadence: string(),
});
export type PerformanceFormType = InferType<typeof performanceReportSchema>;

export type CreateReportProps = {
  setIsCreateFormOpen: (b: boolean) => void;
  selectedReport: PerformanceReport | null;
  refetch: () => void;
  setReportType: (t: string) => void;
  reportType: string;
};

export type CreateReportWrapperProps = {
  setIsCreateFormOpen: (b: boolean) => void;
  selectedReport: PerformanceReport | null;
  refetch: () => void;
};

export const toEnum = (arr: number[]) => Object.fromEntries(arr.map((n) => [n, n]));

export const OVERALL = 'OVERALL';
export const NAME_MAX_LENGTH = 30;
export const CUSTOM = 'CUSTOM';
export const DEFAULT_DAILY_CADENCE = '9'; // 9:00 AM
export const DEFAULT_TIME_SLICE = 'LAST_30_DAYS';

export enum STATUS_TYPES {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  EMPTY = 'EMPTY',
}
export type ReportsTableProps = {
  setIsCreateFormOpen: (b: boolean) => void;
  selectedReport: PerformanceReport | null;
  setSelectedReport: (report: PerformanceReport | null) => void;
  refetch: () => void;
  pageSize: number;
  setPageSize: (n: number) => void;
  page: number;
  setPage: (n: number) => void;
  search: string;
  setSearch: (s: string) => void;
  setSortBy: (s: string | null) => void;
  setSortDirection: (s: string | null) => void;
};

export type AllTouchPreviewTableProps = {
  page: number;
  setPage: (n: number) => void;
  pageSize: number;
  setPageSize: (n: number) => void;
}

export type CheckboxField = { onChange: (values: string[]) => void; value: string[] };

export const allTouchReportSchema = object().shape({
  reportType: string().trim().required(),
  name: string().required('Report name is required'),
  dateRange: object().shape({
    startDate: mixed<dayjs.Dayjs>().nullable(),
    endDate: mixed<dayjs.Dayjs>().nullable(),
    period: string(),
  }),
  conversionWindow: number().required(),
  campaign: string().required('Campaign is required'),
  eventType: string().required(),
  subcategories: array().optional(),
  dimensionFilters: array(
    object().shape({
      metric: string(),
      value: string().optional(),
      include: array(string()).nullable(),
      exclude: array(string()).nullable(),
    }),
  ),
  reportFormat: string().required(),
  isScheduled: boolean(),
  emails: array().when('isScheduled', {
    is: true,
    then: (schema) =>
      schema.min(1, 'At least one email is required').required('Email list is required'),
    otherwise: (schema) => schema,
  }),
  cadenceType: string().required(),
  dailyCadence: string().required(),
  weeklyCadence: string(),
  monthlyCadence: string(),
});
export type AllTouchReportFormType = InferType<typeof allTouchReportSchema>;
