/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { Image } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { Upload } from 'feather-icons-react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Field, Indicator, Input, LinkButton } from 'components/common';

import { usePublisher } from '../../modules/publisher/usePublisher';
import { JPEG_FILE_MIME_TYPE, PNG_FILE_MIME_TYPE } from '../../utils/constants';
import { UploadButton, UploadFile } from '../common/UploadButton';
import {
  PublisherFormInputs,
  EditPublisherModalProps,
  MAX_LENGTH,
  schema,
} from './publisherSettingsTypes';

export const EditPublisherModal = ({ onClose, selectedPublisher }: EditPublisherModalProps) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm<PublisherFormInputs>({
    resolver: yupResolver(schema),
    values: {
      id: selectedPublisher?.id || '',
      name: selectedPublisher?.name || '',
      displayName: selectedPublisher?.displayName || '',
      icon: selectedPublisher?.icon || null,
    },
  });
  const { updatePublisher, errorText } = usePublisher();
  const [iconFile, setIconFile] = useState<any | null>(null);

  const handleLogoUpload = useCallback(async ({ file }: UploadChangeParam<UploadFile>) => {
    return setIconFile(file);
  }, []);

  const handleFormSubmit = handleSubmit(({ name }) => {
    updatePublisher({
      id: selectedPublisher?.id || '',
      name: name || '',
      displayName: name || '',
      icon: iconFile,
    });
    handleCancel();
  });

  const handleCancel = () => {
    reset();
    setIconFile(null);
    onClose();
  };

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-snug text-secondary-black">
            Edit publisher
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          <form
            onSubmit={handleFormSubmit}
            className="w-full gap-4 flex flex-col justify-start items-start"
          >
            <div className="self-stretch justify-start items-center gap-4 inline-flex">
              {watch('icon') || iconFile ? (
                <div className="w-20 h-20 bg-primary-gray-50 rounded-lg border border-primary-gray-100 justify-center items-center flex">
                  {iconFile ? (
                    <Image
                      src={iconFile ? URL.createObjectURL(iconFile as Blob) : ''}
                      width={80}
                      height={80}
                      className="object-contain"
                      preview={false}
                    />
                  ) : (
                    <Image
                      src={watch('icon') || ''}
                      width={80}
                      height={80}
                      className="object-contain"
                      preview={false}
                    />
                  )}
                </div>
              ) : (
                <UploadButton
                  onChange={handleLogoUpload}
                  accept={`${PNG_FILE_MIME_TYPE}, ${JPEG_FILE_MIME_TYPE}`}
                >
                  <div className="w-20 h-20 bg-primary-gray-50 rounded-lg border border-primary-gray-100 justify-center items-center flex cursor-pointer">
                    <Upload className="w-6 h-6 relative" />
                  </div>
                </UploadButton>
              )}
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                <UploadButton
                  onChange={handleLogoUpload}
                  accept={`${PNG_FILE_MIME_TYPE}, ${JPEG_FILE_MIME_TYPE}`}
                >
                  <LinkButton className="h-auto py-0">Upload logo</LinkButton>
                </UploadButton>
                <div className="self-stretch text-primary-gray-500 text-base-xs leading-4">
                  Recommended specs: JPG format. Square 80px x 80px
                  <br />
                  minimum dimensions
                </div>
                {watch('icon') || iconFile ? (
                  <Button
                    variant="danger"
                    onClick={() => {
                      setIconFile(null);
                      setValue('icon', null, { shouldValidate: true, shouldDirty: true });
                    }}
                  >
                    Remove logo
                  </Button>
                ) : null}
              </div>
            </div>
            <Field
              label={'Publisher name'}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder="Enter publisher name..."
                  maxLength={MAX_LENGTH}
                />
              )}
              control={control}
              name={'name'}
              error={errors.name?.message}
              secondaryLabel={
                <div className="text-base-xs leading-tight font-medium flex items-center text-primary-gray-500">
                  {`${(watch('name') || '').length}/${MAX_LENGTH}`}
                </div>
              }
            />
            <Field
              label={'Xandr Publisher ID'}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder=""
                  maxLength={MAX_LENGTH}
                  disabled={true}
                />
              )}
              control={control}
              name={'id'}
              error={errors.id?.message}
            />
          </form>

          <div className="stretch-to-form gap-3">
            {errorText && (
              <Indicator variant="danger" size="lg">
                {errorText}
              </Indicator>
            )}
          </div>
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={handleCancel} className="w-20">
            Cancel
          </Button>
          <Button
            size="md"
            variant="primary"
            disabled={!isValid || (!isDirty && !iconFile)}
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
