import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { ChevronLeft, Check } from 'feather-icons-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { expandConstants, omitProps } from 'utils';

import { Metrics } from 'components/Incrementality/incrementalityTypes';
import {
  Button,
  Checkbox,
  DateRangePicker,
  Field,
  FieldLabel,
  IconButton,
  Indicator,
  Input,
  RadioButton,
  RadioGroup,
} from 'components/common';
import { useDefaultDateRangePresets } from 'components/common/dateRangePicker/dateRangePresets';
import { EmailInput } from 'components/common/emailInput';
import { Select, Option } from 'components/common/select';

import {
  useConstantsQuery,
  useInsertionOrdersQuery,
  useSubcategoriesQuery,
} from 'modules/apiData/dataApiSlice';
import NotificationService from 'modules/notifications/notificationService';
import { useOrganization } from 'modules/organization/useOrganization';
import { AllTouchReport, ReportStatusResponse } from 'modules/report/reportApiTypes';
import { useReport } from 'modules/report/useReport';

import {
  HOURS,
  allTouchReportSchema,
  AllTouchReportFormType,
  CreateReportProps,
  toEnum, //   OVERALL,
  NAME_MAX_LENGTH,
  CUSTOM,
  STATUS_TYPES,
  DEFAULT_DAILY_CADENCE,
  DEFAULT_TIME_SLICE, //   CheckboxField,
} from './ReportsTypes';
import { usePolling } from './usePolling';
import { usePerformance } from '../../modules/performance/usePerformance';
import { AllTouchPreviewTable } from './AllTouchPreviewTable';

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const CreateAllTouchReport = ({
  setIsCreateFormOpen,
  selectedReport,
  refetch,
  setReportType,
  reportType,
}: CreateReportProps) => {
  const { currentAdvertiserId } = useOrganization();
  const { saveAllTouchReport, isLoading, downloadReport, getReportStatus } = useReport();
  const { getAllTouch }  = usePerformance();
  const { data: constants } = useConstantsQuery(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const skipQuery = {
    skip: !currentAdvertiserId,
  };

  // const MAX_LENGHT = 35;
  // const truncateWithTooltip = (text: string) =>
  //   text.length > MAX_LENGHT ? (
  //     <Tooltip
  //       className="text-base-sm"
  //       title={<span className="text-base-sm font-normal">{text}</span>}
  //     >
  //       {text}
  //     </Tooltip>
  //   ) : (
  //     { text }
  //   );
  const createDimensionOption = (value: string) => ({
    label: value,
    value,
  });

  const { data: subcategories = {} } = useSubcategoriesQuery(currentAdvertiserId, skipQuery);
  const { data: insertionOrders } = useInsertionOrdersQuery(currentAdvertiserId, skipQuery);
  const { presets: defaultDateRangePresets } = useDefaultDateRangePresets();
  const newReportRef = useRef<AllTouchReport | null>(null);

  const reportTypes = expandConstants((constants && constants.ReportType) || {});
  const conversionWindow = toEnum((constants && constants.ConversionWindow) || []);
  const formats = expandConstants((constants && constants.ReportFormat) || {});
  const cadence = expandConstants((constants && omitProps(constants.Cadence, ['YEARLY'])) || {});
  const weeklyCadence = expandConstants((constants && constants.CadenceWeekly) || {});
  const monthlyCadence = expandConstants((constants && constants.CadenceMonthly) || {});
  const dailyCadence = expandConstants(HOURS);
  const timeSlices = (constants && constants.TimeSlice) || {};

  const { startDate, endDate, cadenceType, cadenceValue, timeSlice } = selectedReport || {};

  const existingDateRange =
    startDate && endDate
      ? { startDate: dayjs(startDate), endDate: dayjs(endDate), period: timeSlice }
      : null;

  const defaultTimeSlice = timeSlices[DEFAULT_TIME_SLICE];
  const defaultPreset = defaultDateRangePresets.find((p) => p.label === defaultTimeSlice);
  const defaultDateRange = {
    startDate: defaultPreset?.value?.[0],
    endDate: defaultPreset?.value?.[1],
    period: DEFAULT_TIME_SLICE,
  };

  const getCadenceValue = (type: { value: string }) =>
    cadenceType === type.value ? cadenceValue : null;

  const eventTypeOptions = Object.entries(Metrics).map(([key, { label }]) => ({
    label,
    value: key,
  }));

  const campaigns = insertionOrders?.map((io) => io.displayName).map(createDimensionOption) || [];

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AllTouchReportFormType>({
    resolver: yupResolver(allTouchReportSchema),
    mode: 'onSubmit',
    values: {
      reportType: selectedReport?.reportType || reportType,
      name: selectedReport?.name || '',
      dateRange: existingDateRange || defaultDateRange,
      conversionWindow: conversionWindow[selectedReport?.conversionWindow || 30],
      reportFormat: selectedReport?.reportFormat || formats.CSV.value,
      campaign: selectedReport?.campaign || '',
      eventType: selectedReport?.eventType || eventTypeOptions?.[0].value,
      subcategories: selectedReport?.subcategories || [],
      isScheduled: selectedReport?.isScheduled || false,
      emails: selectedReport?.emails || [],
      cadenceType: cadenceType || cadence.DAILY.value,
      dailyCadence:
        selectedReport?.cadenceHour?.toString() || dailyCadence[DEFAULT_DAILY_CADENCE].value,
      weeklyCadence: getCadenceValue(cadence.WEEKLY) || weeklyCadence.SUNDAY.value,
      monthlyCadence: getCadenceValue(cadence.MONTHLY) || monthlyCadence.FIRST_OF_MONTH.value,
    },
  });
  const headerText = selectedReport ? 'Edit report' : 'Create a report';
  const isScheduleEnabled = watch('isScheduled');
  const isCustomDateRange = watch('dateRange')?.period === CUSTOM;

  const availableSubcategories = subcategories[watch('eventType')];

  useEffect(() => {
    if (!availableSubcategories || !availableSubcategories.length) setValue('subcategories', []);
  }, [availableSubcategories, setValue]);

  useEffect(() => {
    if (isCustomDateRange) setValue('isScheduled', false);
  }, [isCustomDateRange, setValue]);

  const setEmails = useCallback(
    (value: string[]) => setValue('emails', value, { shouldValidate: true }),
    [setValue],
  );

  const fetchAllTouchData = () => {
    const { dateRange, campaign, conversionWindow, eventType, subcategories } = getValues();

    if (currentAdvertiserId && campaign.length !== 0 && dateRange?.startDate && dateRange?.endDate && subcategories) {
      getAllTouch({
        advertiserId: currentAdvertiserId,
        campaign,
        conversionWindow,
        startDateTime: dateRange.startDate.format('YYYY-MM-DD HH:mm:ss.SSS'),
        endDateTime: dateRange.endDate.format('YYYY-MM-DD HH:mm:ss.SSS'),
        eventType,
        subcategories,
        page,
        size: pageSize,
      });
    }
  }

  const selectedDateRange = watch('dateRange');
  const selectedConversionWindow = watch('conversionWindow');
  const selectedCampaign = watch('campaign')
  const selectedEventType = watch('eventType');
  const selectedSubcategories = watch('subcategories');

  useEffect(() => {
    fetchAllTouchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdvertiserId,
    selectedDateRange,
    selectedConversionWindow,
    selectedCampaign,
    selectedEventType,
    selectedSubcategories,
    page,
    pageSize]);

  const handleFormSubmit = handleSubmit(async (data) => {
    const { startDate, endDate, period: timeSlice } = data.dateRange;

    const basePayload = omitProps(data, [
      'reportType',
      'dateRange',
      'weeklyCadence',
      'monthlyCadence',
      'dailyCadence',
    ]);

    const weeklyCadence =
      data.cadenceType === cadence.WEEKLY.value ? { weeklyCadence: data.weeklyCadence } : {};
    const monthlyCadence =
      data.cadenceType === cadence.MONTHLY.value ? { monthlyCadence: data.monthlyCadence } : {};
    const reportId = selectedReport ? { reportId: selectedReport.id } : {};

    const payload = {
      ...basePayload,
      advertiserId: currentAdvertiserId,
      startDate: startDate ? startDate?.format(dateFormat) : '',
      endDate: endDate ? endDate?.format(dateFormat) : '',
      timeSlice,
      ...weeklyCadence,
      ...monthlyCadence,
      dailyCadence: parseInt(data.dailyCadence),
      ...reportId,
    };

    newReportRef.current = await saveAllTouchReport(payload);
    refetch();
  });

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    await handleFormSubmit();

    if (newReportRef?.current?.id) setIsCreateFormOpen(false);
  };

  const stopCondition = ({ status }: ReportStatusResponse) =>
    status === STATUS_TYPES.COMPLETED || status === STATUS_TYPES.EMPTY;

  const { isPolling, startPolling, stopPolling } = usePolling(5000, stopCondition, 6);

  const handleStatusResponse = async (
    reportStatus: ReportStatusResponse | undefined,
    onCompleted: () => Promise<void>,
  ) => {
    if (reportStatus) {
      const { status } = reportStatus;

      if (status === STATUS_TYPES.COMPLETED) {
        await onCompleted();
        setIsCreateFormOpen(false);
      }
      if (status === STATUS_TYPES.EMPTY) {
        NotificationService.warn(
          'We’re unable to generate the report at this time as there’s no data available.',
        );
        setIsCreateFormOpen(false);
      }
      if (status === STATUS_TYPES.IN_PROGRESS) {
        NotificationService.warn(
          'Report generation is taking a bit longer than expected. You can download it later from the Reports table.',
        );
      }
    }
  };

  const handleDownloadClick = async () => {
    try {
      await handleFormSubmit();

      const { id, name } = newReportRef.current as AllTouchReport;
      const reportStatus = await startPolling(() => getReportStatus(id));
      const onCompleted = () => downloadReport(id, name);

      handleStatusResponse(reportStatus, onCompleted);
    } catch (err) {
      NotificationService.error('An error occured while downloading a report');
    } finally {
      newReportRef.current = null;
    }
  };

  const handleBack = () => {
    stopPolling();
    setIsCreateFormOpen(false);
  };

  return (
    <div className="w-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex ctv-create-report">
      <div className="h-20 w-full border-b border-primary-gray-100 flex-col justify-start items-start inline-flex">
        <div className="w-256 px-8 py-5 justify-between items-start inline-flex">
          <div className="justify-start items-center gap-1 flex">
            <IconButton Icon={ChevronLeft} variant="tertiary" onClick={handleBack} />
            <div className="text-secondary-black text-heading-2xl font-bold leading-loose">
              {headerText}
            </div>
          </div>
          <div className="justify-start items-center gap-2 flex">
            <Button
              variant="outline"
              htmlType="submit"
              form="reportForm"
              isLoading={isLoading || isPolling}
            >
              Save
            </Button>
            <Button
              variant="primary"
              disabled={isScheduleEnabled}
              onClick={handleDownloadClick}
              isLoading={isLoading || isPolling}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
      <form
        id="reportForm"
        onSubmit={handleSave}
        className="w-full p-8 flex-col justify-start items-start gap-8 inline-flex"
      >
        <div>
          <div className="mb-1.5 ">
            <FieldLabel htmlFor="reportType">Report type</FieldLabel>
          </div>
          <RadioGroup
            value={reportType}
            onChange={(option) => {
              setReportType(option.target.value);
            }}
          >
            <div className="self-stretch justify-start items-start gap-3 inline-flex">
              <RadioButton cards value={reportTypes.PERFORMANCE.value} disabled={!!selectedReport}>
                <div className="justify-center items-center gap-1.5 inline-flex">
                  <div className="card-header">Performance report</div>
                  {reportType === reportTypes.PERFORMANCE.value && (
                    <Check className="text-primary-electric-500 w-5 h-5" />
                  )}
                </div>
                <div className="card-text">
                  A breakdown of selected dimensions so that you can effectively monitor which
                  aspects of your campaign are performing.
                </div>
              </RadioButton>
              <RadioButton cards value={reportTypes.ALL_TOUCH.value}>
                <div className="justify-center items-center gap-1.5 inline-flex">
                  <div className=" card-header">All touch report</div>
                  {reportType === reportTypes.ALL_TOUCH.value && (
                    <Check className="text-primary-electric-500 w-5 h-5" />
                  )}
                </div>
                <div className=" card-text">All touch report</div>
              </RadioButton>
            </div>
          </RadioGroup>
        </div>
        <div className="w-256 flex flex-col justify-start items-start gap-8">
          <div className="w-256 flex flex-col justify-start items-start gap-8">
            <div className="flex flex-row gap-8 w-full">
              <Field
                label={'Name of report'}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    className="w-128"
                    {...field}
                    invalid={invalid}
                    placeholder="Enter report name..."
                    maxLength={NAME_MAX_LENGTH}
                  />
                )}
                control={control}
                name={'name'}
                error={errors.name?.message}
                secondaryLabel={
                  <div className="text-base-xs leading-tight font-medium flex items-center text-primary-gray-500">
                    {`${watch('name')?.length}/${NAME_MAX_LENGTH}`}
                  </div>
                }
              />

              <Field
                label={'Date range'}
                render={({ field }) => {
                  const { value, ...rest } = field;

                  return (
                    <DateRangePicker
                      value={[value.startDate, value.endDate]}
                      timeSlice={!isCustomDateRange ? watch('dateRange')?.period : ''}
                      presetOptions={{ withCampaignToDatePreset: true }}
                      {...rest}
                    />
                  );
                }}
                control={control}
                name={'dateRange'}
                error={errors.dateRange?.message}
              />
            </div>
          </div>

          <div className="w-256 flex flex-col justify-start items-start gap-8">
            <div className="flex flex-row gap-8 w-full">
              <Field
                label={'Conversion window (days)'}
                render={({ field }) => (
                  <Select className="w-128" {...field}>
                    {Object.values(conversionWindow).map((value, i) => (
                      <Option key={`methodology-dropdown-${i}`} value={value}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                )}
                control={control}
                name={'conversionWindow'}
                error={errors.conversionWindow?.message}
              />
              <Field
                label={'Campaign'}
                render={({ field }) => (
                  <Select className="w-128" {...field} placeholder="Select campaign">
                    {campaigns.map((c, i) => (
                      <Option key={`campaign-type-dropdown-${i}`} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                )}
                control={control}
                name={'campaign'}
                error={errors.campaign?.message}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start gap-8 w-256">
          <div className="flex flex-row gap-8 w-full">
            <Field
              label={'Event type'}
              render={({ field }) => (
                <Select className="w-128" {...field}>
                  {eventTypeOptions.map((metric, i) => (
                    <Option key={`event-type-dropdown-${i}`} value={metric.value}>
                      {metric.label}
                    </Option>
                  ))}
                </Select>
              )}
              control={control}
              name={'eventType'}
              error={errors.eventType?.message}
            />

            {availableSubcategories && availableSubcategories.length ? (
              <Field
                label={'Subcategory'}
                render={({ field }) => (
                  <Select
                    className="w-128"
                    {...field}
                    mode="multiple"
                    placeholder="All subcategories"
                  >
                    {availableSubcategories.map((sc, i) => (
                      <Option key={`subcategory-dropdown-${i}`} value={sc}>
                        {sc}
                      </Option>
                    ))}
                  </Select>
                )}
                control={control}
                name={'subcategories'}
                error={errors.subcategories?.message}
              />
            ) : null}
          </div>
        </div>

        <div className="w-full">
          <AllTouchPreviewTable
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </div>

        <div className="w-96 flex-col justify-start items-start gap-8 inline-flex">
          <Field
            label={'Format'}
            render={({ field }) => (
              <RadioGroup {...field} className="w-full">
                <div className="self-stretch flex">
                  {Object.values(formats)
                    .reverse()
                    .map(({ value, label }, i) => (
                      <RadioButton key={`formats-radio-${i}`} value={value}>
                        <div className="justify-center items-center gap-1.5 inline-flex">
                          {label}
                          <Check className="text-primary-electric-500 w-5 h-5 check-icon" />
                        </div>
                      </RadioButton>
                    ))}
                </div>
              </RadioGroup>
            )}
            control={control}
            name={'reportFormat'}
            error={errors.reportFormat?.message}
          />

          <Field
            disabled={isCustomDateRange}
            render={({ field }) => (
              <Checkbox {...field} checked={isCustomDateRange ? false : field.value}>
                <span className="text-base-sm font-medium leading-tight">
                  Scheduled email report
                </span>
              </Checkbox>
            )}
            control={control}
            name={'isScheduled'}
            error={errors.isScheduled?.message}
          />

          {isScheduleEnabled ? (
            <EmailInput
              setEmails={setEmails}
              label="Recipient email"
              placeholder="Enter..."
              emails={watch('emails')}
              fieldError={errors.emails?.message}
            />
          ) : null}
        </div>
        <div className="w-168 flex-col justify-start items-start gap-8 inline-flex">
          {isScheduleEnabled ? (
            <Field
              label={'Cadence'}
              render={({ field }) => (
                <RadioGroup {...field} className="w-full">
                  <div className="self-stretch flex">
                    {Object.values(cadence).map(({ value, label }, i) => (
                      <RadioButton key={`cadence-radio-${i}`} value={value}>
                        <div className="justify-center items-center gap-1.5 inline-flex">
                          {label}
                          <Check className="text-primary-electric-500 w-5 h-5 check-icon" />
                        </div>
                      </RadioButton>
                    ))}
                  </div>
                </RadioGroup>
              )}
              control={control}
              name={'cadenceType'}
              error={errors.cadenceType?.message}
            />
          ) : null}

          {isScheduleEnabled ? (
            <div className="h-10 w-full justify-start items-start gap-2 inline-flex">
              {watch('cadenceType') === 'WEEKLY' ? (
                <Field
                  render={({ field }) => (
                    <Select className="w-full" {...field}>
                      {Object.values(weeklyCadence).map(({ value, label }, i) => (
                        <Option key={`weeks-dropdown-${i}`} value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  )}
                  control={control}
                  name={'weeklyCadence'}
                  error={errors.weeklyCadence?.message}
                />
              ) : null}

              {watch('cadenceType') === 'MONTHLY' ? (
                <Field
                  render={({ field }) => (
                    <Select className="w-full" {...field}>
                      {Object.values(monthlyCadence).map(({ value, label }, i) => (
                        <Option key={`months-dropdown-${i}`} value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  )}
                  control={control}
                  name={'monthlyCadence'}
                  error={errors.monthlyCadence?.message}
                />
              ) : null}

              <Field
                render={({ field }) => (
                  <Select className="w-full max-w-88" {...field}>
                    {Object.values(dailyCadence).map(({ value, label }, i) => (
                      <Option key={`hours-dropdown-${i}`} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                )}
                control={control}
                name={'dailyCadence'}
                error={errors.dailyCadence?.message}
              />
            </div>
          ) : null}

          {isCustomDateRange ? (
            <Indicator variant="warning" size="lg">
              This is not available when selecting a custom date range. Please select a
              pre-determined date range in order to schedule email reports.
            </Indicator>
          ) : null}
        </div>
      </form>
    </div>
  );
};
