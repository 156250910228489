/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';
import { formatDate } from 'utils';

import { Dimensions, Metrics } from 'components/Incrementality/incrementalityTypes';
import { DateRangeValue } from 'components/common';

import { PagingRequestValues } from 'modules/organization/organizationApiTypes';

import { DAILY_CADENCE, SPEND_BUDGET_METRIC } from './constants';
import {
  AdvancedConfiguration,
  AllTouchRequest,
  AllTouchResponse,
  AnalyticsResponse,
  BudgetResponse,
  CreateViewRequest,
  Creative,
  DeleteViewRequest,
  DeleteViewResponse,
  DimensionFilter,
  FilterWidgets,
  Halo,
  IncrementalityFilters,
  IncrementalityResponse,
  PerformanceResponse,
  PerformanceState,
  Publisher,
  TabKeys,
  View,
  WidgetResponse,
} from './performanceApiTypes';

export const initialState: PerformanceState = {
  isWidgetsLoading: false,
  isChartLoading: false,
  isViewLoading: false,
  isGridLoading: false,
  isFiltersLoading: false,
  isIncrementalityLoading: false,
  isHaloLoading: false,
  isAllTouchPreviewLoading: false,
  views: null,
  widgets: null,
  displayWidgets: null,
  error: null,
  currentViewId: '',
  analytics: null,
  analyticsPage: 0,
  analyticsPageSize: 10,
  analyticsOrder: null,
  analyticsOrderBy: null,
  allTouch: null,
  incrementalityOrder: null,
  haloOrder: null,
  charts: null,
  creatives: null,
  creativeNames: null,
  publishers: null,
  customView: null,
  selectedMetrics: null,
  incrementalities: null,
  overallIncrementality: null,
  startDate: null,
  endDate: null,
  dateRange: null,
  range: null,
  budget: null,
  selectedColumns: null,
  globalFilterDimensions: [],
  selectedDimensions: [null, null],
  cadence: DAILY_CADENCE,
  cumulative: false,
  advancedConfiguration: null,
  defaultAdvancedConfiguration: null,
  incrementalityFilters: { dimension: Dimensions.OVERALL.value, metric: Metrics.LEADS.value },
  haloFilter: Metrics.LEADS.value,
  activeTab: TabKeys.REAL_IMPACT.value,
  showSecondFilter: true,
};

export const performanceSlice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    clearPerformanceState(state: PerformanceState) {
      return initialState;
    },
    getViewsStart(state: PerformanceState, action: PayloadAction<string>) {
      state.isViewLoading = true;
    },
    getViewsSuccess(state: PerformanceState, action: PayloadAction<View[]>) {
      state.isViewLoading = false;
      state.views = action.payload;
      state.customView = null;
      state.error = null;
    },
    getViewsFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isViewLoading = false;
    },
    saveViewStart(state: PerformanceState, action: PayloadAction<CreateViewRequest>) {
      state.isViewLoading = true;
    },
    saveViewSuccess(state: PerformanceState, action: PayloadAction<View[]>) {
      state.isViewLoading = false;
      state.views = action.payload;
      state.customView = null;
      state.currentViewId = '';
      state.error = null;
    },
    saveViewFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isViewLoading = false;
    },
    setSelectedMetrics(state: PerformanceState, action: PayloadAction<string[]>) {
      state.selectedMetrics = action.payload;
      state.isWidgetsLoading = false;
    },
    setCustomViewStart(state: PerformanceState, action: PayloadAction<View>) {
      const existingViewIndex = state.views?.findIndex((view) => view.name === action.payload.name);

      if (existingViewIndex !== undefined && existingViewIndex !== -1) {
        if (!state.views) {
          state.views = [];
        }

        state.views[existingViewIndex] = action.payload;
      } else {
        state.views = [action.payload, ...(state.views ? state.views : [])];
      }

      state.customView = action.payload;
      state.currentViewId = '0';
    },
    deleteViewStart(state: PerformanceState, action: PayloadAction<DeleteViewRequest>) {
      state.isViewLoading = true;
    },
    deleteViewSuccess(state: PerformanceState, action: PayloadAction<DeleteViewResponse>) {
      state.isViewLoading = false;
      const views = action.payload.views;

      state.customView = null;
      state.views = views;

      if (action.payload.isSelectedViewDeleted) {
        const defaultView = views.find((view) => view.isDefault);
        const currentViewId = defaultView ? defaultView.viewId : views[0].viewId;

        state.currentViewId = currentViewId || '';
      }

      state.error = null;
    },
    deleteViewFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isViewLoading = false;
    },
    setCurrentViewId(state: PerformanceState, action: PayloadAction<string>) {
      state.currentViewId = action.payload;
    },
    getWidgetDataStart(state: PerformanceState) {
      state.isWidgetsLoading = true;
    },
    getWidgetDataSuccess(state: PerformanceState, action: PayloadAction<WidgetResponse>) {
      state.isWidgetsLoading = false;
      state.widgets = action.payload;
      state.error = null;
    },
    getWidgetDataFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isWidgetsLoading = false;
    },
    getBudgetDataStart(state: PerformanceState) {
      state.isWidgetsLoading = true;
    },
    getBudgetDataSuccess(state: PerformanceState, action: PayloadAction<BudgetResponse>) {
      state.isWidgetsLoading = false;
      state.budget = action.payload;
      state.error = null;
    },
    getBudgetDataFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isWidgetsLoading = false;
    },
    clearBudgetData(state: PerformanceState) {
      state.budget = null;
    },
    getChartDataStart(state: PerformanceState) {
      state.isChartLoading = true;
    },
    getChartDataSuccess(
      state: PerformanceState,
      action: PayloadAction<PerformanceResponse | null>,
    ) {
      state.isChartLoading = false;
      state.charts = action.payload;
      state.error = null;
    },
    getChartDataFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isChartLoading = false;
    },
    getIncrementalityDataStart(state: PerformanceState) {
      state.isIncrementalityLoading = true;
    },
    getIncrementalityDataSuccess(
      state: PerformanceState,
      action: PayloadAction<IncrementalityResponse>,
    ) {
      state.isIncrementalityLoading = false;
      state.incrementalities = action.payload.incrementalities;
      state.overallIncrementality = action.payload.overallIncrementality;
      state.error = null;
    },
    getIncrementalityDataFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isIncrementalityLoading = false;
    },
    getHaloDataStart(state: PerformanceState) {
      state.isHaloLoading = true;
    },
    getHaloDataSuccess(state: PerformanceState, action: PayloadAction<Halo | null>) {
      state.isHaloLoading = false;
      state.halo = action.payload;
      state.error = null;
    },
    getHaloDataFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isHaloLoading = false;
    },
    getAnalyticsDataStart(state: PerformanceState) {
      state.isGridLoading = true;
    },
    getAnalyticsDataSuccess(
      state: PerformanceState,
      action: PayloadAction<AnalyticsResponse | null>,
    ) {
      state.isGridLoading = false;
      state.analytics = action.payload;
      state.error = null;
    },
    getAnalyticsDataFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isGridLoading = false;
    },
    getAllTouchDataStart(state: PerformanceState, action: PayloadAction<AllTouchRequest>) {
      state.isAllTouchPreviewLoading = true;
    },
    getAllTouchDataSuccess(state: PerformanceState, action: PayloadAction<AllTouchResponse | null>) {
      state.isAllTouchPreviewLoading = false;
      state.allTouch = action.payload;
      state.error = null;
    },
    getAllTouchDataFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isAllTouchPreviewLoading = false;
    },
    getCreativesStart(state: PerformanceState, action: PayloadAction<PagingRequestValues>) {
      state.isFiltersLoading = true;
    },
    getCreativesSuccess(state: PerformanceState, action: PayloadAction<Creative[]>) {
      state.isFiltersLoading = false;
      state.creatives = action.payload;
      state.error = null;
    },
    getCreativesFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFiltersLoading = false;
    },
    getCreativeNamesStart(state: PerformanceState, action: PayloadAction<PagingRequestValues>) {
      state.isFiltersLoading = true;
    },
    getCreativeNamesSuccess(state: PerformanceState, action: PayloadAction<string[]>) {
      state.isFiltersLoading = false;
      state.creativeNames = action.payload;
      state.error = null;
    },
    getCreativeNamesFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFiltersLoading = false;
    },
    getPublishersStart(state: PerformanceState, action: PayloadAction<PagingRequestValues>) {
      state.isFiltersLoading = true;
    },
    getPublishersSuccess(state: PerformanceState, action: PayloadAction<Publisher[]>) {
      state.isFiltersLoading = false;
      state.publishers = action.payload;
      state.error = null;
    },
    getPublishersFailure(state: PerformanceState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFiltersLoading = false;
    },
    setDate(state: PerformanceState, action: PayloadAction<DateRangeValue>) {
      state.dateRange = action.payload;
      state.startDate = formatDate(action.payload?.[0]);
      state.endDate = formatDate(action.payload?.[1]);
    },
    setRange(state: PerformanceState, action: PayloadAction<string>) {
      state.range = action.payload;
    },
    setSelectedColumns(state: PerformanceState, action: PayloadAction<string[]>) {
      state.selectedColumns = action.payload;
    },
    clearSelectedColumns(state: PerformanceState) {
      state.selectedColumns = null;
    },
    setGlobalFilterDimensions(state: PerformanceState, action: PayloadAction<DimensionFilter[]>) {
      state.globalFilterDimensions = action.payload;
    },
    setShowSecondFilter(state: PerformanceState, action: PayloadAction<boolean>) {
      state.showSecondFilter = action.payload;
    },
    clearGlobalFilterDimensions(state: PerformanceState) {
      state.globalFilterDimensions = [];
    },
    setAdvancedConfiguration(
      state: PerformanceState,
      action: PayloadAction<AdvancedConfiguration>,
    ) {
      state.advancedConfiguration = action.payload;
    },
    setDefaultAdvancedConfiguration(
      state: PerformanceState,
      action: PayloadAction<AdvancedConfiguration>,
    ) {
      state.defaultAdvancedConfiguration = action.payload;
    },
    resetAdvancedConfiguration(state: PerformanceState) {
      state.advancedConfiguration = state.defaultAdvancedConfiguration;
    },
    setIncrementalityFilter(state: PerformanceState, action: PayloadAction<IncrementalityFilters>) {
      state.incrementalityFilters = action.payload;
    },
    setHaloFilter(state: PerformanceState, action: PayloadAction<string>) {
      state.haloFilter = action.payload;
    },
    filterWidgetsStart(state: PerformanceState, action: PayloadAction<WidgetResponse>) {},
    filterWidgets(state: PerformanceState, action: PayloadAction<FilterWidgets>) {
      state.isWidgetsLoading = true;
      const widgets = action.payload.widgets;

      if (widgets) {
        state.displayWidgets = action.payload.viewMetrics.map((viewMetric: string) => ({
          displayName: action.payload.displayNames[viewMetric].shownName,
          unit: action.payload.displayNames[viewMetric].unit,
          selected: action.payload.graphs.includes(viewMetric),
          previous: widgets.previous ? widgets.previous[viewMetric] || 0 : 0,
          current: widgets.current ? widgets.current[viewMetric] || 0 : 0,
          percent: widgets.percent ? widgets.percent[viewMetric] || 0 : 0,
          hasPopover: viewMetric === SPEND_BUDGET_METRIC,
          timeSlice: action.payload.timeSlice,
          timeSliceOption: action.payload.timeSliceOption,
          key: viewMetric,
        }));
      }
      state.selectedMetrics = action.payload.graphs;
      state.isWidgetsLoading = false;
    },
    invalidatePerformanceCache(state: PerformanceState) {},
    invalidateApiData(state: PerformanceState) {},
    setCadence(state: PerformanceState, action: PayloadAction<string>) {
      state.cadence = action.payload;
    },
    setCumulative(state: PerformanceState, action: PayloadAction<boolean>) {
      state.cumulative = action.payload;
    },
    setSelectedDimensions(
      state: PerformanceState,
      action: PayloadAction<[string | null, string | null]>,
    ) {
      state.selectedDimensions = action.payload;
    },
    setAnalyticsPage(state: PerformanceState, action: PayloadAction<number>) {
      state.analyticsPage = action.payload;
    },
    setAnalyticsPageSize(state: PerformanceState, action: PayloadAction<number>) {
      state.analyticsPageSize = action.payload;
    },
    setAnalyticsOrder(state: PerformanceState, action: PayloadAction<string | null>) {
      state.analyticsOrder = action.payload;
    },
    setAnalyticsOrderBy(state: PerformanceState, action: PayloadAction<string | null>) {
      state.analyticsOrderBy = action.payload;
    },
    setIncrementalityOrder(state: PerformanceState, action: PayloadAction<string | null>) {
      state.incrementalityOrder = action.payload;
    },
    setHaloOrder(state: PerformanceState, action: PayloadAction<string | null>) {
      state.haloOrder = action.payload;
    },
    setActiveTab(state: PerformanceState, action: PayloadAction<string>) {
      state.activeTab = action.payload;
    },
  },
});

export const getPerformanceState = ({ performance }: AppState) => performance;
export const getError = (state: AppState) => getPerformanceState(state).error;
export const getCustomView = (state: AppState) => getPerformanceState(state).customView;
export const getSelectedMetrics = (state: AppState) => getPerformanceState(state).selectedMetrics;
export const currentViewId = (state: AppState) => getPerformanceState(state).currentViewId;
export const views = (state: AppState) => getPerformanceState(state).views;
export const startDate = (state: AppState) => getPerformanceState(state).startDate;
export const endDate = (state: AppState) => getPerformanceState(state).endDate;
export const activeTab = (state: AppState) => getPerformanceState(state).activeTab;
export const range = (state: AppState) => getPerformanceState(state).range;
export const dateRange = (state: AppState) => getPerformanceState(state).dateRange;
export const selectedColumns = (state: AppState) => getPerformanceState(state).selectedColumns;
export const budget = (state: AppState) => getPerformanceState(state).budget;
export const getGlobalFilterDimensions = (state: AppState) =>
  getPerformanceState(state).globalFilterDimensions;
export const cadence = (state: AppState) => getPerformanceState(state).cadence;
export const cumulative = (state: AppState) => getPerformanceState(state).cumulative;
export const getSelectedDimensions = (state: AppState) =>
  getPerformanceState(state).selectedDimensions;
export const advancedConfiguration = (state: AppState) =>
  getPerformanceState(state).advancedConfiguration;
export const incrementalityFilters = (state: AppState) =>
  getPerformanceState(state).incrementalityFilters;
export const haloFilter = (state: AppState) => getPerformanceState(state).haloFilter;
export const getAnalyticsPage = (state: AppState) => getPerformanceState(state).analyticsPage;
export const getAnalyticsPageSize = (state: AppState) =>
  getPerformanceState(state).analyticsPageSize;
export const getAnalyticsOrder = (state: AppState) => getPerformanceState(state).analyticsOrder;
export const getAnalyticsOrderBy = (state: AppState) => getPerformanceState(state).analyticsOrderBy;
export const getIncrementalityOrder = (state: AppState) =>
  getPerformanceState(state).incrementalityOrder;
export const getHaloOrder = (state: AppState) => getPerformanceState(state).haloOrder;

export const {
  getViewsStart,
  getViewsSuccess,
  getViewsFailure,
  getWidgetDataStart,
  getWidgetDataSuccess,
  getWidgetDataFailure,
  setCurrentViewId,
  saveViewStart,
  saveViewSuccess,
  saveViewFailure,
  deleteViewStart,
  deleteViewSuccess,
  deleteViewFailure,
  getChartDataStart,
  getChartDataSuccess,
  getChartDataFailure,
  getAnalyticsDataStart,
  getAnalyticsDataSuccess,
  getAnalyticsDataFailure,
  getAllTouchDataStart,
  getAllTouchDataSuccess,
  getAllTouchDataFailure,
  getCreativesFailure,
  getCreativesStart,
  getCreativesSuccess,
  getCreativeNamesFailure,
  getCreativeNamesStart,
  getCreativeNamesSuccess,
  getPublishersFailure,
  getPublishersStart,
  getPublishersSuccess,
  filterWidgets,
  setCustomViewStart,
  getIncrementalityDataFailure,
  getIncrementalityDataStart,
  getIncrementalityDataSuccess,
  setSelectedMetrics,
  setDate,
  setRange,
  getBudgetDataFailure,
  getBudgetDataStart,
  getBudgetDataSuccess,
  setSelectedColumns,
  filterWidgetsStart,
  setGlobalFilterDimensions,
  setCadence,
  setCumulative,
  setSelectedDimensions,
  invalidatePerformanceCache,
  setAnalyticsPage,
  setAnalyticsPageSize,
  setAnalyticsOrder,
  setAnalyticsOrderBy,
  setIncrementalityFilter,
  invalidateApiData,
  clearGlobalFilterDimensions,
  clearPerformanceState,
  setShowSecondFilter,
  clearBudgetData,
  setIncrementalityOrder,
  getHaloDataFailure,
  getHaloDataStart,
  getHaloDataSuccess,
  setHaloOrder,
  setActiveTab,
  setHaloFilter,
  clearSelectedColumns,
  setAdvancedConfiguration,
  setDefaultAdvancedConfiguration,
  resetAdvancedConfiguration,
} = performanceSlice.actions;
export const performance = performanceSlice.reducer;
