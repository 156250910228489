/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/named
import { object, string, array, bool, InferType } from 'yup';

import { Button, Field, CheckboxGroup, Skeleton, Switch } from 'components/common';
import { EmailInput } from 'components/common/emailInput';
import { Select, Option } from 'components/common/select';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import { useAuth } from 'modules/auth/useAuth';
import { Agency } from 'modules/organization/organizationApiTypes';
import { useOrganization } from 'modules/organization/useOrganization';
import { useUser } from 'modules/user/useUser';
import { InviteAgency, UserRoles } from 'modules/user/userApiTypes';

import { NestedCheckBoxGroup } from './NestedCheckBoxGroup';
import {
  atLeastOneOf,
  filterUserRoles,
  getIsShowingAgencies,
  mapAgenciesToCheckboxOptions,
  mapAgenciesToInviteRequest,
  mapValuesToInviteRequest,
  mergeAgencies,
  toggleDisabled,
} from './utils';

type InviteMembersModalProps = {
  onClose: () => void;
};

type InviteFormSubmitType = { emails: string[]; role: string; agencies: InviteAgency[] };

const schema = object()
  .shape({
    role: string().oneOf(Object.keys(UserRoles)).required('Role is required'),
    emails: array().min(1, 'At least one email is required').required('Email list is required'),
    advertisers: array().of(string()).default([]),
    withFutureAdvertisers: array().of(string()).default([]),
    fullAccess: bool(),
  })
  .test('at-least-one', 'At least one of advertisers or future must have a value', atLeastOneOf);

export type FormType = InferType<typeof schema>;

export const InviteMembersModal = ({ onClose }: InviteMembersModalProps) => {
  const { inviteUser } = useUser();
  const [agency, setAgency] = useState<Agency | undefined>(undefined);
  const { getCampaigns, agencies, currentAgencyId, isLoading } = useOrganization();
  const { role } = useAuth();
  const { data: constants } = useConstantsQuery(null);
  const defaultAdvertiserId = agency?.advertisers[0].id || '';

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<FormType>({
    resolver: yupResolver(schema),
    values: {
      role: '',
      emails: [],
      advertisers: [],
      fullAccess: false,
      withFutureAdvertisers: [],
    },
  });

  const handleInviteFormSubmit = handleSubmit((data) => {
    const { advertisers, withFutureAdvertisers, fullAccess, role, emails } = data;
    const payload: InviteFormSubmitType = {
      emails,
      role,
      agencies: [],
    };

    if (getIsShowingAgencies(role)) {
      const inviteAgencies = mapValuesToInviteRequest(agencies, advertisers as string[]);
      const futureAgencies = mapAgenciesToInviteRequest(
        agencies,
        withFutureAdvertisers as string[],
      );

      const agenciesPayload = fullAccess
        ? mergeAgencies(inviteAgencies, futureAgencies)
        : inviteAgencies;

      payload.agencies = agenciesPayload;
    }

    inviteUser(payload);
    onClose();
  });

  const setEmails = useCallback(
    (value: string[]) => setValue('emails', value, { shouldValidate: true }),
    [setValue],
  );

  useEffect(() => {
    const agency = agencies.find((el) => el.id === currentAgencyId);

    setAgency(agency);
  }, [agencies, currentAgencyId]);

  useEffect(() => {
    if (defaultAdvertiserId) {
      getCampaigns(defaultAdvertiserId);
    }
  }, [defaultAdvertiserId]);

  const filteredUserRoles = useMemo(
    () => filterUserRoles(constants && constants.RoleHierarchy, role),
    [constants, role],
  );

  const { fullAccess, withFutureAdvertisers, role: selectedRole } = watch();
  const showAgencies = getIsShowingAgencies(selectedRole);

  const agenciesCheckboxes = useMemo(
    () => agencies && mapAgenciesToCheckboxOptions(agencies),
    [agencies],
  );

  const agencyOptions = useMemo(() => {
    const agenciesWithAdvertisers = agenciesCheckboxes.filter((agency) => agency.subOptions.length);

    return fullAccess
      ? agenciesWithAdvertisers.map(({ value, label, subOptions }) => {
          const isFuture = withFutureAdvertisers.includes(value);

          return {
            value,
            label,
            subOptions: subOptions.map((so) => toggleDisabled(so, isFuture)),
          };
        })
      : agenciesWithAdvertisers;
  }, [agenciesCheckboxes, fullAccess, withFutureAdvertisers]);

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-snug text-secondary-black">
            Invite new member(s)
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <EmailInput setEmails={setEmails} />
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          <form
            onSubmit={handleInviteFormSubmit}
            className="w-full gap-4 flex flex-col justify-start items-start"
          >
            <Field
              label={'Role'}
              render={({ field }) => (
                <Select className="w-full" {...field}>
                  {filteredUserRoles.map(({ label, value }, i) => (
                    <Option key={`role-dropdown-${i}`} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              )}
              control={control}
              name={'role'}
              error={errors.role?.message}
            />
            {showAgencies ? (
              <>
                <Field
                  label={'Agencies'}
                  render={({ field }) => {
                    return isLoading ? (
                      <div className="widget-container flex w-full">
                        {Array.from({ length: 4 }).map((_, index) => (
                          <Skeleton key={index} className="flex-1 h-[140px]" />
                        ))}
                      </div>
                    ) : (
                      <div className="basis-0 p-2.5 w-full overflow-y-scroll h-72 rounded-lg border border-primary-gray-100 bg-primary-gray-50">
                        <NestedCheckBoxGroup
                          options={agencyOptions}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </div>
                    );
                  }}
                  control={control}
                  className=""
                  name={'advertisers'}
                />
                <Field
                  render={({ field }) => {
                    return (
                      <Switch {...field} className="text-base-sm">
                        Auto grant access to future advertisers for select agencies
                      </Switch>
                    );
                  }}
                  control={control}
                  name={'fullAccess'}
                />
                {fullAccess ? (
                  <Field
                    render={({ field }) => {
                      return isLoading ? (
                        <div className="widget-container flex w-full">
                          {Array.from({ length: 1 }).map((_, index) => (
                            <Skeleton key={index} className="flex-1 h-[140px]" />
                          ))}
                        </div>
                      ) : (
                        <div className="basis-0 p-2.5 w-full overflow-y-scroll h-52 rounded-lg border border-primary-gray-100 bg-primary-gray-50">
                          <CheckboxGroup
                            {...field}
                            options={agenciesCheckboxes || []}
                            column
                            className="gap-2"
                            handleSelectAll={field.onChange}
                            selectAllLabel="All Agencies"
                          />
                        </div>
                      );
                    }}
                    control={control}
                    className=""
                    name={'withFutureAdvertisers'}
                  />
                ) : null}
              </>
            ) : null}
          </form>
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={onClose} className="w-20">
            Cancel
          </Button>
          <Button size="md" variant="primary" disabled={!isValid} onClick={handleInviteFormSubmit}>
            Send invitation
          </Button>
        </div>
      </div>
    </div>
  );
};
