import { useState } from 'react';
import { expandConstants } from 'utils';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';

import { CreateAllTouchReport } from './CreateAllTouchReport';
import { CreatePerformanceReport } from './CreatePerformanceReport';
import { CreateReportWrapperProps } from './ReportsTypes';

export const CreateReportWrapper = ({
  setIsCreateFormOpen,
  selectedReport,
  refetch,
}: CreateReportWrapperProps) => {
  const { data: constants } = useConstantsQuery(null);

  const reportTypes = expandConstants((constants && constants.ReportType) || {});
  const [reportType, setReportType] = useState(
    selectedReport?.reportType || reportTypes.PERFORMANCE.value,
  );

  return reportType === reportTypes.PERFORMANCE.value ? (
    <CreatePerformanceReport
      setIsCreateFormOpen={setIsCreateFormOpen}
      selectedReport={selectedReport}
      refetch={refetch}
      setReportType={setReportType}
      reportType={reportType}
    />
  ) : (
    <CreateAllTouchReport
      setIsCreateFormOpen={setIsCreateFormOpen}
      selectedReport={selectedReport}
      refetch={refetch}
      setReportType={setReportType}
      reportType={reportType}
    />
  );
};
