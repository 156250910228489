export const REPORTS_URL = 'reports';

export const REPORTS_TEMPLATES_URL = 'reports/templates';

export const REPORTS_DOWNLOAD_URL = 'reports/download';

export const REPORTS_STATUS_URL = 'reports/status';

export const ALL_TOUCH_REPORTS_URL = 'reports/all-touch';

export const PERFORMANCE_REPORTS_URL = 'reports/performance';
