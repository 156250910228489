import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
import { useMemo } from 'react';

import { useConstantsQuery, useInsertionOrdersQuery } from 'modules/apiData/dataApiSlice';
import { InsertionOrdersCampaign } from 'modules/misc/miscApiTypes';
import { useOrganization } from 'modules/organization/useOrganization';
import { DimensionFilter } from 'modules/performance/performanceApiTypes';
import { usePerformance } from 'modules/performance/usePerformance';

dayjs.extend(minMax);

type Preset = { label: string; value: RangeValueType<Dayjs> };
export type UseDefaultDateRangePresetsOptions = {
  withCampaignToDatePreset?: boolean;
};

const getDate = (value?: number) => (value !== undefined ? dayjs().add(value, 'd') : dayjs());

const getStartDate = (value?: number) => getDate(value).startOf('d');

const getEndDate = (value?: number) => getDate(value).endOf('d');

const getLastQuarter = () => {
  const currentMonth = dayjs().month();
  const currentYear = dayjs().year();
  const currentQuarter = Math.floor(currentMonth / 3);
  const lastQuarterYear = currentQuarter === 0 ? currentYear - 1 : currentYear;
  const lastQuarter = currentQuarter === 0 ? 3 : currentQuarter - 1;
  const lastQuarterValue: RangeValueType<Dayjs> = [
    dayjs()
      .set('y', lastQuarterYear)
      .set('M', lastQuarter * 3)
      .startOf('M'),
    dayjs()
      .set('y', lastQuarterYear)
      .set('M', lastQuarter * 3 + 2)
      .endOf('M'),
  ];

  return lastQuarterValue;
};

const getEarliestDate = (dates: Dayjs[]) => dayjs.min(...dates);
const getAllSelectedCampaignNames = ({ include = [], exclude = [] }: DimensionFilter) => [
  ...include,
  ...exclude,
];

const getCampaignsStartDate = (campaigns: InsertionOrdersCampaign[]) =>
  campaigns.map((c) => dayjs(c.startDate));

const createCampaignToDatePreset = (
  globalFilterDimensions: DimensionFilter[] | [] = [],
  campaigns?: InsertionOrdersCampaign[],
) => {
  if (!campaigns || !campaigns.length) return null;
  const selectedCampaigns = globalFilterDimensions
    ?.filter((d) => d.dimension === 'CAMPAIGN')
    .flatMap(getAllSelectedCampaignNames);

  const selectedCampaignStartDates = campaigns?.filter((c) => selectedCampaigns?.includes(c.name));

  const campaignsDates = selectedCampaignStartDates?.length
    ? getCampaignsStartDate(selectedCampaignStartDates)
    : getCampaignsStartDate(campaigns);

  const earliestCampaingStart = getEarliestDate(campaignsDates);

  return earliestCampaingStart && earliestCampaingStart.isBefore(getEndDate())
    ? {
        label: 'Campaign to date',
        value: [earliestCampaingStart, getEndDate()] as RangeValueType<Dayjs>,
      }
    : null;
};

export const useDefaultDateRangePresets = ({
  withCampaignToDatePreset,
}: UseDefaultDateRangePresetsOptions = {}): { presets: Preset[], isCampaignsLoaded: boolean } => {
  const { currentAdvertiserId } = useOrganization();
  const { data: constants } = useConstantsQuery(null);
  const { data: campaigns, isSuccess: isCampaignsLoaded } = useInsertionOrdersQuery(currentAdvertiserId, {
    skip: !currentAdvertiserId,
  });
  const timeSlices = useMemo(() => (constants && constants.TimeSlice) || {}, [constants]);
  const { globalFilterDimensions } = usePerformance();

  const campaignToDatePreset = withCampaignToDatePreset
    ? createCampaignToDatePreset(globalFilterDimensions, campaigns)
    : null;

  const presets: Preset[] = [
    { label: timeSlices?.CUSTOM, value: [undefined, undefined] },
    { label: timeSlices?.TODAY, value: [getStartDate(), getEndDate()] },
    { label: timeSlices?.YESTERDAY, value: [getStartDate(-1), getEndDate(-1)] },
    { label: timeSlices?.LAST_7_DAYS, value: [getStartDate(-7), getEndDate(-1)] },
    { label: timeSlices?.LAST_30_DAYS, value: [getStartDate(-30), getEndDate(-1)] },
    { label: timeSlices?.LAST_90_DAYS, value: [getStartDate(-90), getEndDate(-1)] },
    { label: timeSlices?.LAST_365_DAYS, value: [getStartDate(-365), getEndDate(-1)] },
    {
      label: timeSlices?.LAST_WEEK,
      value: [
        getDate().startOf('w').add(-1, 'd').startOf('w'),
        getDate().startOf('w').add(-1, 'd').endOf('w'),
      ],
    },
    {
      label: timeSlices?.LAST_MONTH,
      value: [
        getDate().startOf('M').add(-1, 'd').startOf('M'),
        getDate().startOf('M').add(-1, 'd').endOf('M'),
      ],
    },
    { label: timeSlices?.LAST_QUARTER, value: getLastQuarter() },
    {
      label: timeSlices?.LAST_YEAR,
      value: [
        getDate().startOf('y').add(-1, 'd').startOf('y'),
        getDate().startOf('y').add(-1, 'd').endOf('y'),
      ],
    },
    { label: timeSlices?.WEEK_TO_DATE, value: [getDate().startOf('w'), getEndDate()] },
    { label: timeSlices?.MONTH_TO_DATE, value: [getDate().startOf('M'), getEndDate()] },
    { label: timeSlices?.YEAR_TO_DATE, value: [getDate().startOf('y'), getEndDate()] },
    ...(campaignToDatePreset ? [campaignToDatePreset] : []),
  ];

  return { presets, isCampaignsLoaded };
};

export const useDateRangePresets = (
  minDate?: Dayjs,
  maxDate?: Dayjs,
  options?: UseDefaultDateRangePresetsOptions,
): Preset[] => {
  const { presets: defaultPresets } = useDefaultDateRangePresets(options);

  if (minDate || maxDate) {
    const minDateValue = minDate ? minDate.startOf('d') : null;
    const maxDateValue = maxDate ? maxDate.endOf('d') : null;

    return defaultPresets.filter(({ value }) => {
      if (!value[0] || !value[1]) {
        return true;
      }
      if ((minDateValue && value[0] < minDateValue) || (maxDateValue && value[1] > maxDateValue)) {
        return false;
      }

      return true;
    });
  }

  return defaultPresets;
};
