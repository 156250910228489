import { formatNumber } from 'utils';

import { PerformanceSidebar } from 'components/PerformanceSidebar/PerformanceSidebar';
import { InfoTooltip } from 'components/common';

import { usePerformance } from 'modules/performance/usePerformance';

export const IncrementalitySidebar = () => {
  const { overallIncrementality } = usePerformance();
  const overall =
    overallIncrementality && overallIncrementality.incrementality_lift
      ? overallIncrementality.incrementality_lift
      : 0;

  return (
    <PerformanceSidebar>
      <div className="self-stretch grow shrink basis-0 px-4 pb-4 flex-col justify-between items-start flex">
        <div className="self-stretch h-[328px] flex-col justify-start items-start gap-12 flex">
          <div className="self-stretch h-[104px] flex-col justify-start items-start gap-1 flex">
            <div className="text-heading-5xl font-bold leading-[52px]">
              {formatNumber(overall / 100)}x
            </div>
            <div className="self-stretch text-base-lg text-primary-gray-500 font-medium leading-normal">
              Users who see your ads are{' '}
              {overallIncrementality?.incrementality_lift.toLocaleString()}% more likely to convert.
            </div>
          </div>
          <div className="self-stretch h-44 font-medium leading-tight flex-col justify-start items-start gap-6 flex">
            <div className="self-stretch pl-3 border-l-2 border-primary-electric-500 flex-col justify-start items-start gap-2 flex">
              <div className="text-base-sm">
                Exposed count: {overallIncrementality?.exposed_reach.toLocaleString()}
              </div>
              <div className="text-base-sm">
                Exposed conversions: {overallIncrementality?.exposed_conversions.toLocaleString()}
              </div>
              <div className="text-base-sm">
                Exposed conversion rate:{' '}
                {formatNumber(overallIncrementality?.exposed_conversions_rate)}%
              </div>
            </div>
            <div className="self-stretch pl-3 border-l-2 border-primary-gray-200 flex-col justify-start items-start gap-2 flex">
              <div className="text-base-sm">
                Controlled count: {overallIncrementality?.control_reach.toLocaleString()}
              </div>
              <div className="text-base-sm">
                Controlled conversions:{' '}
                {overallIncrementality?.control_conversions.toLocaleString()}
              </div>
              <div className="text-base-sm">
                Controlled conversion rate:{' '}
                {overallIncrementality?.control_conversions_rate}%
              </div>
            </div>
          </div>
        </div>
        <div className="text-primary-gray-500 justify-start items-center gap-1.5 inline-flex">
          <InfoTooltip
            title={
              <div className="text-base-sm font-normal leading-tight">
                Incrementality demonstrates the effectiveness of your digital advertising by
                comparing the conversion rate of users who were exposed to your ads versus users who
                were not.
              </div>
            }
          />
          <div className="text-base-sm font-medium leading-tight">How Incrementality works</div>
        </div>
      </div>
    </PerformanceSidebar>
  );
};
