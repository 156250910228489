import { Advertiser } from 'modules/organization/organizationApiTypes';

export const UserRoles = {
  SUPER_ADMIN: {
    label: 'Super Admin',
    value: 'SUPER_ADMIN',
  },
  INTERNAL_ADMIN: {
    label: 'Internal Admin',
    value: 'INTERNAL_ADMIN',
  },
  ADMIN: {
    label: 'Admin',
    value: 'ADMIN',
  },
  LIMITED_ACCESS: {
    label: 'Limited Access',
    value: 'LIMITED_ACCESS',
  },
  COOL_MEDIA_SELLER: {
    label: 'Cool Media Seller',
    value: 'COOL_MEDIA_SELLER',
  },
} as const;

export type RoleKey = keyof typeof UserRoles;

export enum Status {
  ACTIVE = 'Active',
  INVITED = 'Invited',
  CONFIRMED = 'Confirmed',
}

export type UserState = {
  isLoading: boolean;
  isInvited: boolean;
  error: string | null;
  members: User[] | null;
};

export type UpdateMemberRequestValues = {
  subId: string;
  role?: string | null;
  agencies?: InviteAgency[];
  currentSelectedAgencyId: string;
};

export type FileUploadValues = {
  targetId: string; // user sub
  type: string;
  file: string;
};

export type InviteAgency = {
  agencyId: string;
  advertiserIds: string[];
};

export type UserInviteRequestValues = {
  emails: string[];
  role: string;
  agencies?: InviteAgency[];
};

export type MembersPagingRequestValue = {
  agencyId: string | null;
  email: string;
  page: number;
  size: number;
};

export type MembersPagingResponse = {
  totalCount: number;
  data: User[];
};

export type FullAccessAgency = {
  id: string;
  name: string;
};

export type User = {
  id: string;
  subId: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  role: string;
  status: string;
  advertisers: Advertiser[];
  creationDate: string;
  modificationDate: string;
  insertionOrders: UserInsertionOrder[];
  agencies: FullAccessAgency[];
};

export type UserInsertionOrder = {
  id: UserInsertionOrderId;
  displayName: string;
};

export type UserInsertionOrderId = {
  personId: string;
  insertionOrderId: number;
};
