/* eslint-disable @typescript-eslint/no-explicit-any */
import { object, string } from 'yup';

import { Publisher } from '../../modules/publisher/publisherApiTypes';

export interface PublisherFormInputs {
  id: string;
  name?: string;
  displayName: string;
  icon?: string | null;
}
export const MAX_LENGTH = 30;

export const schema = object().shape({
  id: string().trim().required('ID is required'),
  name: string().optional(),
  displayName: string().trim().required('Display name is required'),
  icon: string().nullable(),
});

export type CreatePublisherModalProps = {
  onClose: () => void;
};

export type EditPublisherModalProps = {
  onClose: () => void;
  selectedPublisher: Publisher | null | undefined;
};

export type DeletePublisherModalProps = {
  onClose: () => void;
  selectedPublisher: undefined | Publisher;
};

export type UploadFileModalProps = {
  onClose: () => void;
};
