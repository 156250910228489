/* eslint-disable @typescript-eslint/no-explicit-any */
import { Upload } from 'antd';
import { Upload as UploadIcon } from 'feather-icons-react';
import { useState } from 'react';

import { ReactComponent as IconFile } from 'assets/images/icons/file.svg';

import { Button } from 'components/common';

import { usePublisher } from '../../modules/publisher/usePublisher';
import { UploadFileModalProps } from './publisherSettingsTypes';

const { Dragger } = Upload;

export const UploadFileModal = ({ onClose }: UploadFileModalProps) => {
  const { uploadPublisherFile } = usePublisher();
  const [file, setFile] = useState<any | null>(null);
  const [fileList, setFileList] = useState<any[]>([]);

  const handleSubmit = () => {
    uploadPublisherFile({
      file,
    });
    handleCancel();
  };

  const handleCancel = () => {
    setFile(null);
    setFileList([]);
    onClose();
  };

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload(file: any) {
      setFile(file);
      setFileList([file]);

      return false;
    },
    fileList,
    onChange(info: any) {
      if (info.file.status === 'done') {
        alert(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        alert(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-snug text-secondary-black">
            Upload file
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          <form
            onSubmit={handleSubmit}
            className="w-full gap-4 flex flex-col justify-start items-start"
          >
            <div className="self-stretch justify-start items-center gap-4 inline-flex">
              <Dragger {...props} className="w-full bg-primary-electric-50 rounded-md">
                <div className="flex flex-col items-center justify-center pt-2">
                  <p className="ant-upload-drag-icon">
                    <UploadIcon className="text-primary-electric-600" />
                  </p>
                  <p className="ant-upload-text text-primary-electric-600">
                    Select .csv / .xslx file to upload <br /> or drag and drop here
                  </p>
                </div>
              </Dragger>
            </div>
            {fileList.length > 0 && (
              <div className="w-full mt-2 p-2 border border-primary-gray-200 bg-primary-gray-25 rounded-md">
                <ul className="list-none">
                  {fileList.map((f, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <IconFile />
                      <li key={index} className="text-gray-800">
                        {f.name}
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </form>
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={handleCancel} className="w-20">
            Cancel
          </Button>
          <Button size="md" variant="primary" disabled={!file} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
