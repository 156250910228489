/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';

import { PagingRequestValues } from 'modules/organization/organizationApiTypes';
import { getCurrentAdvertiserId } from 'modules/organization/organizationSlice';

import {
  AllTouchReport,
  AllTouchReportCreatePayload,
  PerformanceReport,
  PerformanceReportCreatePayload, // ReportCreatePayload,
  ReportStatusResponse,
} from './reportApiTypes';
import {
  deleteReportStart,
  getReportsStart,
  getReportState,
  getReportTemplatesStart,
  savePerformanceReportStart,
  saveAllTouchReportStart,
  downloadReportStart,
  getReportStatusStart,
} from './reportSlice';

export const useReport = () => {
  const dispatch = useDispatch();
  const state = useSelector(getReportState);
  const isLoading = state.isLoading;
  const errorText = state.error;
  const reports = state.reports;
  const createdReport = state.createdReport;
  const reportTemplates = state.reportTemplates;
  const advertiserId = useSelector(getCurrentAdvertiserId);

  function getReports({ page, size, sortBy, direction, search }: PagingRequestValues) {
    dispatch(getReportsStart({ page, size, sortBy, direction, advertiserId, search }));
  }

  function getReportTemplates() {
    dispatch(getReportTemplatesStart());
  }

  function savePerformanceReport(
    report: PerformanceReportCreatePayload,
  ): Promise<PerformanceReport> {
    return new Promise((resolve, reject) => {
      dispatch(savePerformanceReportStart({ report, resolve, reject }));
    });
  }

  function saveAllTouchReport(report: AllTouchReportCreatePayload): Promise<AllTouchReport> {
    return new Promise((resolve, reject) => {
      dispatch(saveAllTouchReportStart({ report, resolve, reject }));
    });
  }

  function deleteReport(reportId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      dispatch(deleteReportStart({ reportId, resolve, reject }));
    });
  }

  function downloadReport(reportId: string, reportName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      dispatch(downloadReportStart({ reportId, reportName, resolve, reject }));
    });
  }

  function getReportStatus(reportId: string): Promise<ReportStatusResponse> {
    return new Promise((resolve, reject) => {
      dispatch(getReportStatusStart({ reportId, resolve, reject }));
    });
  }

  return {
    getReports,
    getReportTemplates,
    savePerformanceReport,
    saveAllTouchReport,
    deleteReport,
    downloadReport,
    getReportStatus,
    errorText,
    isLoading,
    reportTemplates,
    reports,
    createdReport,
  };
};
