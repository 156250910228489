/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';

import { DateRangeValue } from 'components/common';

import { getCurrentAdvertiserId } from 'modules/organization/organizationSlice';

import {
  AdvancedConfiguration,
  AllTouchRequest,
  DimensionFilter,
  IncrementalityFilters,
} from './performanceApiTypes';
import {
  deleteViewStart,
  getAnalyticsDataStart,
  getBudgetDataStart,
  getChartDataStart,
  getCreativeNamesStart,
  getCreativesStart,
  getIncrementalityDataStart,
  getAllTouchDataStart,
  getPerformanceState,
  getPublishersStart,
  getViewsStart,
  getWidgetDataStart,
  saveViewStart,
  setAdvancedConfiguration,
  setCadence,
  setCumulative,
  setCurrentViewId,
  setCustomViewStart,
  setDate,
  setGlobalFilterDimensions,
  setRange,
  setSelectedColumns,
  setSelectedMetrics,
  setSelectedDimensions,
  setAnalyticsPage,
  setAnalyticsPageSize,
  setAnalyticsOrder,
  setAnalyticsOrderBy,
  setIncrementalityOrder,
  setIncrementalityFilter,
  setShowSecondFilter,
  getHaloDataStart,
  setHaloOrder,
  setActiveTab,
  setHaloFilter,
} from './performanceSlice';

export const usePerformance = () => {
  const dispatch = useDispatch();
  const state = useSelector(getPerformanceState);
  const currentAdvertiserId = useSelector(getCurrentAdvertiserId);
  const displayWidgets = state.displayWidgets;
  const widgets = state.widgets;
  const views = state.views;
  const charts = state.charts;
  const analytics = state.analytics;
  const isWidgetsLoading = state.isWidgetsLoading;
  const isViewLoading = state.isViewLoading;
  const isChartLoading = state.isChartLoading;
  const isGridLoading = state.isGridLoading;
  const isIncrementalityLoading = state.isIncrementalityLoading;
  const isHaloLoading = state.isHaloLoading;
  const isAllTouchPreviewLoading = state.isAllTouchPreviewLoading;
  const creatives = state.creatives;
  const creativeNames = state.creativeNames;
  const publishers = state.publishers;
  const incrementalities = state.incrementalities;
  const overallIncrementality = state.overallIncrementality;
  const halo = state.halo;
  const allTouch = state.allTouch;
  const selectedView = state.currentViewId;
  const startDate = state.startDate;
  const endDate = state.endDate;
  const dateRange = state.dateRange;
  const range = state.range;
  const budget = state.budget;
  const globalFilterDimensions = state.globalFilterDimensions;
  const selectedCadence = state.cadence;
  const isCumulative = state.cumulative;
  const selectedDimensions = state.selectedDimensions;
  const advancedConfiguration = state.advancedConfiguration;
  const isMetricsSelected = Boolean(state.selectedMetrics && state.selectedMetrics.length > 0);
  const selectedMetrics = state.selectedMetrics;
  const analyticsPage = state.analyticsPage;
  const analyticsPageSize = state.analyticsPageSize;
  const analyticsOrder = state.analyticsOrder;
  const analyticsOrderBy = state.analyticsOrderBy;
  const incrementalityOrder = state.incrementalityOrder;
  const haloOrder = state.haloOrder;
  const selectedColumns = state.selectedColumns;
  const incrementalityFilters = state.incrementalityFilters;
  const showSecondFilter = state.showSecondFilter;
  const activeTab = state.activeTab;
  const haloFilter = state.haloFilter;

  function getViews() {
    if (currentAdvertiserId) {
      dispatch(getViewsStart(currentAdvertiserId));
    }
  }

  function setViewId(viewId: string) {
    dispatch(setCurrentViewId(viewId));
  }

  function setMetrics(metrics: string[]) {
    dispatch(setSelectedMetrics(metrics));
  }

  function saveView(name: string, isDefault: boolean) {
    dispatch(saveViewStart({ advertiserId: currentAdvertiserId, name: name, isDefault }));
  }

  function setCustomView(metrics: string[]) {
    dispatch(
      setCustomViewStart({
        name: 'Custom',
        metrics: metrics,
        isCustom: true,
        graphs: [],
        viewId: '0',
        advertiserId: currentAdvertiserId,
      }),
    );
  }

  function updateView(name: string, isDefault: boolean, viewId: string) {
    dispatch(saveViewStart({ advertiserId: currentAdvertiserId, name: name, isDefault, viewId }));
  }

  function deleteView(viewId: string) {
    dispatch(deleteViewStart({ viewId: viewId, advertiserId: currentAdvertiserId }));
  }

  function getWidgets() {
    dispatch(getWidgetDataStart());
  }

  function getBudget() {
    dispatch(getBudgetDataStart());
  }

  function getCharts() {
    dispatch(getChartDataStart());
  }

  function getIncrementalities() {
    dispatch(getIncrementalityDataStart());
  }

  function getHalo() {
    dispatch(getHaloDataStart());
  }

  function getAnalytics() {
    dispatch(getAnalyticsDataStart());
  }

  function getAllTouch(payload: AllTouchRequest) {
    dispatch(getAllTouchDataStart(payload));
  }

  function pickAnalyticsPage(page: number) {
    dispatch(setAnalyticsPage(page));
  }

  function pickAnalyticsPageSize(size: number) {
    dispatch(setAnalyticsPageSize(size));
  }

  function pickAnalyticsOrder(order: string | null) {
    dispatch(setAnalyticsOrder(order));
  }

  function pickAnalyticsOrderBy(orderBy: string | null) {
    dispatch(setAnalyticsOrderBy(orderBy));
  }

  function pickIncrementalityOrder(order: string | null) {
    dispatch(setIncrementalityOrder(order));
  }

  function pickHaloOrder(order: string | null) {
    dispatch(setHaloOrder(order));
  }

  function pickDate(dates: DateRangeValue) {
    dispatch(setDate(dates));
  }

  function pickGlobalFilterDimensions(dimensions: DimensionFilter[]) {
    dispatch(setGlobalFilterDimensions(dimensions));
  }

  function pickRange(range: string) {
    dispatch(setRange(range));
  }

  function pickActiveTab(tab: string) {
    dispatch(setActiveTab(tab));
  }

  function pickSelectedDimensions(dimensions: [string | null, string | null]) {
    dispatch(setSelectedDimensions(dimensions));
  }

  function pickSelectedColumns(columns: string[]) {
    dispatch(setSelectedColumns(columns));
  }

  function pickShowSecondFilter(show: boolean) {
    dispatch(setShowSecondFilter(show));
  }

  function pickAdvancedConfiguration(configuration: AdvancedConfiguration) {
    dispatch(setAdvancedConfiguration(configuration));
  }

  function getPublishers() {
    dispatch(getPublishersStart({ page: 0, size: 10000 }));
  }

  function getCreatives() {
    dispatch(getCreativesStart({ page: 0, size: 10000 }));
  }

  function getCreativeNames() {
    if (currentAdvertiserId) {
      dispatch(getCreativeNamesStart({ page: 0, size: 10000, advertiserId: currentAdvertiserId }));
    }
  }

  function pickCadence(cadence: string) {
    dispatch(setCadence(cadence));
  }

  function pickCumulative(cumulative: boolean) {
    dispatch(setCumulative(cumulative));
  }

  function pickIncrementalityFilter(filters: IncrementalityFilters) {
    dispatch(setIncrementalityFilter(filters));
  }

  function pickHaloFilter(filter: string) {
    dispatch(setHaloFilter(filter));
  }

  const errorText = state.error;

  return {
    getViews,
    errorText,
    isViewLoading,
    isWidgetsLoading,
    isChartLoading,
    isGridLoading,
    isIncrementalityLoading,
    isHaloLoading,
    isAllTouchPreviewLoading,
    views,
    displayWidgets,
    charts,
    analytics,
    setViewId,
    saveView,
    deleteView,
    getWidgets,
    getCharts,
    getBudget,
    getAnalytics,
    getAllTouch,
    selectedView,
    updateView,
    getPublishers,
    getCreatives,
    getCreativeNames,
    publishers,
    creatives,
    creativeNames,
    widgets,
    setCustomView,
    getIncrementalities,
    incrementalities,
    setMetrics,
    pickDate,
    pickRange,
    range,
    startDate,
    endDate,
    pickAdvancedConfiguration,
    dateRange,
    pickSelectedColumns,
    selectedColumns,
    budget,
    pickGlobalFilterDimensions,
    globalFilterDimensions,
    pickCadence,
    selectedCadence,
    pickCumulative,
    isCumulative,
    pickSelectedDimensions,
    selectedDimensions,
    isMetricsSelected,
    advancedConfiguration,
    selectedMetrics,
    analyticsPage,
    pickAnalyticsPage,
    analyticsPageSize,
    pickAnalyticsPageSize,
    analyticsOrder,
    pickAnalyticsOrder,
    analyticsOrderBy,
    pickAnalyticsOrderBy,
    pickIncrementalityFilter,
    incrementalityFilters,
    showSecondFilter,
    pickShowSecondFilter,
    pickIncrementalityOrder,
    incrementalityOrder,
    overallIncrementality,
    pickHaloOrder,
    haloOrder,
    getHalo,
    halo,
    allTouch,
    pickActiveTab,
    activeTab,
    haloFilter,
    pickHaloFilter,
  };
};
