import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { Button, Field, Input, Progress } from 'components/common';
import { Select, Option } from 'components/common/select';

import { CreateAgencyFormProps, CreateAgencyPayload } from './agencySettingsTypes';
import { mockTimezones } from './mock';

const MAX_LENGTH = 30;

const schema = object().shape({
  name: string().required('Agency name is required'),
  timeZone: string().required('Time zone is required'),
});

export const CreateAgencyForm = ({
  setCurrentStep,
  currentStep,
  setAgencyData,
  visible,
}: CreateAgencyFormProps) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<CreateAgencyPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  const handleFormSubmit = handleSubmit((data) => {
    setAgencyData(data);
    setCurrentStep(2);
  });

  return (
    <div
      className={classNames(
        { 'invisible absolute opacity-0 transition-visibility': !visible },
        'w-full',
      )}
    >
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-snug text-secondary-black">
            Create new agency
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          <form
            onSubmit={handleFormSubmit}
            className="w-full gap-4 flex flex-col justify-start items-start"
          >
            <Field
              label={'Agency name'}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder="Enter agency name..."
                  maxLength={MAX_LENGTH}
                />
              )}
              control={control}
              name={'name'}
              error={errors.name?.message}
              secondaryLabel={
                <div className="text-base-xs leading-tight font-medium flex items-center text-primary-gray-500">
                  {`${watch('name').length}/${MAX_LENGTH}`}
                </div>
              }
            />
            <Field
              label={'Time zone'}
              render={({ field }) => (
                <Select className="w-full" placeholder="Select time zone..." showSearch {...field}>
                  {mockTimezones.map(({ value, label }, i) => (
                    <Option key={`timezone-dropdown-${i}`} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              )}
              control={control}
              name={'timeZone'}
              error={errors.timeZone?.message}
            />
          </form>
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-between items-center flex">
        <div className="items-center justify-between w-full flex">
          <Progress currentStep={currentStep} steps={2} />
          <Button size="md" variant="primary" disabled={!isValid} onClick={handleFormSubmit}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
