/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from 'services/api';

import { PUBLISHER_FILE_UPLOAD_URL, PUBLISHERS_URL } from './constants';
import {
  CreateUpdatePublisherRequest,
  MembersPagingRequestValue,
  MembersPagingResponse,
} from './publisherApiTypes';

const getPublishers = (payload: MembersPagingRequestValue) => {
  return api.get<MembersPagingResponse>(
    `${PUBLISHERS_URL}?page=${payload.page}&size=${payload.size}`,
  );
};

const createPublisher = (payload: CreateUpdatePublisherRequest) => {
  const formData = new FormData();

  formData.append('id', payload.id.toString());
  formData.append('name', payload.displayName);
  formData.append('displayName', payload.displayName);
  formData.append('icon', payload.icon);

  return api.post(PUBLISHERS_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updatePublisher = (payload: CreateUpdatePublisherRequest) => {
  const formData = new FormData();

  formData.append('id', payload.id.toString());
  formData.append('name', payload.displayName);
  formData.append('displayName', payload.displayName);
  formData.append('icon', payload.icon);

  return api.put(PUBLISHERS_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deletePublisher = (action: string) => {
  return api.delete(`${PUBLISHERS_URL}/${action}`);
};

const uploadPublisherFile = (payload: any) => {
  const formData = new FormData();

  formData.append('file', payload?.file);

  return api.post(PUBLISHER_FILE_UPLOAD_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const publisherApi = {
  getPublishers,
  createPublisher,
  updatePublisher,
  deletePublisher,
  uploadPublisherFile,
};
