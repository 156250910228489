import { TableProps } from 'antd';
import { format } from 'date-fns';
import { Download, Edit, Trash2 } from 'feather-icons-react';
import { useState } from 'react';
import { expandConstants } from 'utils';

import { ReactComponent as IconChevronSort } from 'assets/images/icons/chevron-selector-vertical.svg';
import { ReactComponent as IconSearch } from 'assets/images/icons/search-sm.svg';

import { Button, IconButton, Input, Modal, Table, Spin } from 'components/common';
import { TablePagination } from 'components/common/tablePagination';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import { PerformanceReport } from 'modules/report/reportApiTypes';
import { useReport } from 'modules/report/useReport';

import { DeleteReportModal } from './DeleteReportModal';
import { HOURS, ReportsTableProps, STATUS_TYPES } from './ReportsTypes';

const sortIcon = () => <IconChevronSort width={20} height={20} />;
const dateFormatter = (value: string) => (value ? format(new Date(value), 'MMMM d, yyy') : '-');

const sortDirections = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const ReportsTable = ({
  setIsCreateFormOpen,
  selectedReport,
  setSelectedReport,
  refetch,
  pageSize,
  setPageSize,
  page,
  setPage,
  search,
  setSearch,
  setSortBy,
  setSortDirection,
}: ReportsTableProps) => {
  const { downloadReport, reports, isLoading } = useReport();
  const { data: constants } = useConstantsQuery(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const cadence = expandConstants((constants && constants.Cadence) || {});
  const weeklyCadence = (constants && constants.CadenceWeekly) || {};
  const monthlyCadence = (constants && constants.CadenceMonthly) || {};
  const reportType = (constants && constants.ReportType) || {};

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const columns: TableProps<PerformanceReport>['columns'] = [
    {
      dataIndex: 'name',
      title: 'Report',
      width: 256,
      fixed: 'left',
      sorter: true,
      sortIcon,
      showSorterTooltip: false,
      ellipsis: false,
    },
    {
      dataIndex: 'creationDate',
      title: 'Date created',
      width: 256,
      sorter: true,
      sortIcon,
      showSorterTooltip: false,
      render: dateFormatter,
    },
    {
      dataIndex: 'reportType',
      title: 'Report type',
      width: 256,
      sorter: true,
      sortIcon,
      showSorterTooltip: false,
      render: (v) => reportType[v],
    },
    {
      dataIndex: 'isScheduled',
      title: 'Schedule',
      width: 256,
      sorter: false,
      showSorterTooltip: false,
      render: (isScheduled, data) => {
        if (!isScheduled) return '-';
        const { cadenceType, cadenceHour, cadenceValue = null } = data;

        if (cadenceType === cadence.DAILY.value) {
          return `Daily at ${HOURS[cadenceHour as keyof typeof HOURS]}`;
        }

        if (!cadenceType || cadenceValue === null) return '-';
        if (cadenceType === cadence.WEEKLY.value) return weeklyCadence[cadenceValue];

        if (cadenceType === cadence.MONTHLY.value) return monthlyCadence[cadenceValue];

        return '-';
      },
    },
    {
      dataIndex: 'actions',
      title: '',
      width: 128,
      render: (_, data) => (
        <div className="grow shrink basis-0 justify-between items-center flex">
          <IconButton
            variant="tertiary"
            className="bg-transparent hover:bg-primary-gray-25"
            size="sm"
            disabled={isLoading}
            Icon={Edit}
            onClick={() => {
              setSelectedReport(data);
              setIsCreateFormOpen(true);
            }}
          />
          <IconButton
            variant="tertiary"
            className="bg-transparent hover:bg-primary-gray-25"
            size="sm"
            disabled={isLoading || data?.status !== STATUS_TYPES.COMPLETED}
            Icon={Download}
            onClick={() => {
              downloadReport(data.id, data.name);
            }}
          />
          <IconButton
            variant="tertiary"
            className="bg-transparent hover:bg-primary-gray-25"
            size="sm"
            disabled={isLoading}
            Icon={Trash2}
            onClick={() => {
              setSelectedReport(data);
              openDeleteModal();
            }}
          />
        </div>
      ),
    },
  ];

  const handleTableChange: TableProps<PerformanceReport>['onChange'] = (_, __, sorter) => {
    const isArray = Array.isArray(sorter);

    setSortBy(isArray ? null : (sorter.field as string));
    setSortDirection(!isArray && sorter.order ? sortDirections[sorter.order] : null);
  };

  return (
    <div className="w-full h-full px-5 flex-col gap-2 justify-start items-start flex">
      <div className="w-full justify-between items-end inline-flex">
        <div className="text-secondary-black text-heading-3xl font-bold leading-9">Reports</div>
        <Button
          variant="primary"
          onClick={() => {
            setSelectedReport(null);
            setIsCreateFormOpen(true);
          }}
        >
          Create new report
        </Button>
      </div>
      <div className="w-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex">
        {reports ? (
          <div className="grid w-full reports-table-wrapper">
            <div className="px-5 py-3 w-full justify-between items-end inline-flex">
              <div className="grow shrink basis-0 justify-between items-center flex">
                <div className="justify-start items-center gap-1 flex">
                  <div className="text-secondary-black text-base-sm font-semibold leading-snug">
                    Reports
                  </div>
                </div>
                <div className="justify-center items-start gap-2 flex search-container">
                  <Input
                    className="h-10"
                    placeholder="Search..."
                    prefix={<IconSearch />}
                    value={search}
                    onChange={handleSearchChange}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
            </div>
            <Spin spinning={isLoading}>
              <Table
                className="px-5 overflow-x-auto"
                dataSource={reports.data}
                columns={columns}
                pagination={false}
                onChange={handleTableChange}
              />
              <TablePagination
                pageSize={pageSize}
                totalPages={reports.totalPages}
                totalCount={reports.totalCount}
                setPageSize={setPageSize}
                page={page}
                setPage={setPage}
              />
            </Spin>
          </div>
        ) : null}
      </div>
      <Modal open={isDeleteModalOpen} footer={null} closable={false} onCancel={closeDeleteModal}>
        <DeleteReportModal onClose={closeDeleteModal} report={selectedReport} refetch={refetch} />
      </Modal>
    </div>
  );
};
