/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux';

import { uploadFile } from 'services/file/fileService';
import { FileType } from 'services/file/types';

import { orgApi } from './organizationApi';
import {
  AdvertisersPagingRequestValues,
  CreateAdvertiserRequest,
  CreateAgencyRequest,
  defaultAgencyState,
  UpdateAdvertiserRequest,
  UpdateAgencyRequest,
} from './organizationApiTypes';
import {
  createAdvertiserStart,
  createAgencyStart,
  getAgenciesStart,
  getAgenciesByRoleStart,
  getCampaignsStart,
  getOrganizationState,
  setCurrentAdvertiser,
  updateAdvertiserStart,
  updateAgencyStart,
  getCurrentAgencyStart,
  getCurrentAdvertiserId,
  getCurrentAgencyId,
  getCreatedAgency,
  deleteCreatedAgency,
  getCurrentAdvertiser,
  getAdvertisersStart,
  getAdvertiserSelector,
} from './organizationSlice';

export const useOrganization = () => {
  const dispatch = useDispatch();

  const state = useSelector(getOrganizationState);

  function getAgencies() {
    dispatch(getAgenciesStart());
  }

  function getAgenciesByRole() {
    dispatch(getAgenciesByRoleStart());
  }

  function getCurrentAgency() {
    dispatch(getCurrentAgencyStart(state.currentAgencyId));
  }

  function createAgency({ name, timeZone }: CreateAgencyRequest) {
    dispatch(createAgencyStart({ name, timeZone }));
  }

  function createAdvertiser({ name, industry, agencyId }: CreateAdvertiserRequest) {
    dispatch(createAdvertiserStart({ name, industry, agencyId }));
  }

  function updateAdvertiser({ name, industry, advertiserId }: UpdateAdvertiserRequest) {
    dispatch(updateAdvertiserStart({ name, industry, advertiserId }));
  }

  function updateAgency({
    agencyId,
    name,
    timeZone,
    enabledCustomLogo,
    logoUrl,
    wordMark,
  }: UpdateAgencyRequest) {
    dispatch(updateAgencyStart({ agencyId, name, timeZone, enabledCustomLogo, logoUrl, wordMark }));
  }

  function getCampaigns(advertiserId: string) {
    dispatch(getCampaignsStart(advertiserId));
  }

  async function getCampaignsCount(advertiserId: string) {
    const campaigns = await orgApi.getCampaigns(advertiserId);

    return campaigns.length;
  }

  async function uploadWordmark(file: any) {
    const { url } = await uploadFile(file, FileType.WORD_MARK, state.currentAgencyId);

    return `${url}&timestamp=${Date.now()}`;
  }

  async function uploadLogo(file: any) {
    const { url } = await uploadFile(file, FileType.LOGO, state.currentAgencyId);

    return `${url}&timestamp=${Date.now()}`;
  }

  function setCurrentAdvertiserId(advertiserId: string) {
    dispatch(setCurrentAdvertiser(advertiserId));
  }

  function removeCreatedAgency() {
    dispatch(deleteCreatedAgency());
  }

  function getAdvertisers(payload: AdvertisersPagingRequestValues) {
    dispatch(getAdvertisersStart(payload));
  }

  const agencies = state.agencies;
  const agenciesByRole = state.agenciesByRole;
  const campaigns = state.campaigns;
  const currentAgency =
    state.agencies.find((el) => el.id === state.currentAgencyId) || defaultAgencyState;
  const currentAdverisers = currentAgency.advertisers;
  const logoUrl = currentAgency.logoUrl;
  const wordMarkUrl = currentAgency.wordMarkUrl;
  const isLoading = state.isLoading;
  const currentAdvertiserId = useSelector(getCurrentAdvertiserId);
  const currentAgencyId = useSelector(getCurrentAgencyId);
  const createdAgency = useSelector(getCreatedAgency);
  const errorText = state.error;
  const currentAdveriser = useSelector(getCurrentAdvertiser);
  const advertisers = useSelector(getAdvertiserSelector);

  return {
    getAgencies,
    getAgenciesByRole,
    getCurrentAgency,
    getCurrentAdvertiser,
    agencies,
    agenciesByRole,
    campaigns,
    createAdvertiser,
    currentAgency,
    currentAdverisers,
    createAgency,
    updateAdvertiser,
    updateAgency,
    getCampaigns,
    uploadLogo,
    uploadWordmark,
    setCurrentAdvertiserId,
    logoUrl,
    errorText,
    wordMarkUrl,
    currentAdvertiserId,
    currentAgencyId,
    isLoading,
    createdAgency,
    removeCreatedAgency,
    currentAdveriser,
    getCampaignsCount,
    getAdvertisers,
    advertisers,
  };
};
