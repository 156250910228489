/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { Edit, Trash2 } from 'feather-icons-react';
import { useEffect, useState } from 'react';

import { useAuth } from '../../modules/auth/useAuth';
import { Publisher } from '../../modules/publisher/publisherApiTypes';
import { usePublisher } from '../../modules/publisher/usePublisher';
import { UserRoles } from '../../modules/user/userApiTypes';
import { Button, Grid, Modal, Spin } from '../common';
import { CreatePublisherModal } from './CreatePublisherModal';
import { DeletePublisherModal } from './DeletePublisherModal';
import { EditPublisherModal } from './EditPublisherModal';
import { UploadFileModal } from './UploadFileModal';

export const PublishersSettings = () => {
  const { role } = useAuth();
  const canEdit = role === UserRoles.SUPER_ADMIN.value || role === UserRoles.INTERNAL_ADMIN.value;
  const { publishers, getPublishers, isLoading, refreshFlag } = usePublisher();
  const [selectedPublisher, setSelectedPublisher] = useState<undefined | Publisher>(undefined);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const openUploadFileModal = () => setIsUploadFileModalOpen(true);
  const closeUploadFileModal = () => {
    setIsUploadFileModalOpen(false);
  };

  useEffect(() => {
    getPublishers();
  }, []);

  useEffect(() => {
    if (!refreshFlag) getPublishers();
  }, [refreshFlag]);

  const handleEditClick = (id: GridRowId) => () => {
    const publisher = publishers?.find((el: { id: GridRowId }) => el.id === id);

    setSelectedPublisher(publisher);
    openEditModal();

    return id;
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const publisher = publishers?.find((el: { id: GridRowId }) => el.id === id);

    setSelectedPublisher(publisher);
    openDeleteModal();

    return id;
  };

  const columns: GridColDef[] = [
    {
      field: 'publishers',
      headerName: 'Publishers',
      flex: 1,
      headerClassName: 'member-cell',
      minWidth: 100,
      renderCell: (params) => {
        const publisher = params.row;

        return (
          <div className="member-container leading-4 -mt-1">
            <div className="member-surname text-base-sm font-normal">{publisher?.displayName}</div>
          </div>
        );
      },
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const publisher = params.row;

        return (
          <div className="member-container leading-4 -mt-1">
            <div className="member-surname text-base-sm font-normal">{publisher?.id}</div>
          </div>
        );
      },
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const publisher = params.row;

        return (
          <div className="member-container leading-4 -mt-1">
            <div className="member-surname text-base-sm font-normal">{publisher?.creationDate}</div>
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      cellClassName: 'actions',
      flex: 1,
      align: 'right',
      getActions: ({ id, row }) =>
        canEdit
          ? [
              <GridActionsCellItem
                icon={<Edit />}
                key={id}
                label="Edit"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                key={id}
                icon={<Trash2 />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
                disabled={row.role === UserRoles.SUPER_ADMIN.value}
              />,
            ]
          : [],
    },
  ];

  return (
    <div className="w-screen h-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex">
      <div className="p-8 flex-col justify-start items-start inline-flex w-full">
        <div className="flex-col w-full justify-start items-start gap-4 flex">
          <div className="text-secondary-black text-heading-xl font-semibold leading-relaxed">
            Publishers
          </div>
          <div className="flex justify-between items-center w-full">
            {canEdit ? (
              <div className="flex space-x-4">
                <Button variant="primary" size="md" onClick={openCreateModal}>
                  Create new publisher
                </Button>
                <Button variant="primary" size="md" onClick={openUploadFileModal}>
                  Upload file
                </Button>
              </div>
            ) : null}
          </div>
          <Spin spinning={isLoading || refreshFlag}>
            <div className="grid w-full">
              <Grid columns={columns} rows={publishers as any[] | undefined} />
            </div>
          </Spin>
        </div>
      </div>
      <Modal open={isCreateModalOpen} footer={null} closable={false} onCancel={closeCreateModal}>
        <CreatePublisherModal onClose={closeCreateModal} />
      </Modal>
      <Modal open={isEditModalOpen} footer={null} closable={false} onCancel={closeEditModal}>
        <EditPublisherModal onClose={closeEditModal} selectedPublisher={selectedPublisher} />
      </Modal>
      <Modal open={isDeleteModalOpen} footer={null} closable={false} onCancel={closeDeleteModal}>
        <DeletePublisherModal onClose={closeDeleteModal} selectedPublisher={selectedPublisher} />
      </Modal>
      <Modal
        open={isUploadFileModalOpen}
        footer={null}
        closable={false}
        onCancel={closeUploadFileModal}
      >
        <UploadFileModal onClose={closeUploadFileModal} />
      </Modal>
    </div>
  );
};
